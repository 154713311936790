/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __param, __metadata } from 'tslib';
import { Inject, Optional, Input, ViewChild, ElementRef, Component, Injectable, EventEmitter, isDevMode, Output, HostBinding, ContentChild, ViewContainerRef, forwardRef, ChangeDetectorRef, NgZone, TemplateRef, Host, Directive, NgModule } from '@angular/core';
import { FormControl, Validators, FormGroup, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, BehaviorSubject, zip, fromEvent, merge, interval } from 'rxjs';
import { take, filter, auditTime, map, concatMap, takeUntil } from 'rxjs/operators';
import { ToolBarComponent, ToolBarToolComponent, ToolBarButtonComponent, ToolBarModule } from '@progress/kendo-angular-toolbar';
import { DialogContentBase, DialogRef, DialogService, DialogModule } from '@progress/kendo-angular-dialog';
import { isDocumentAvailable, guid, KendoInput, Keys } from '@progress/kendo-angular-common';
import { nodes, Schema, marks, Selection, SelectionRange, TextSelection, Fragment, Slice, NodeSelection, insertNode, expandToWordWrap, toggleInlineFormat, bold, cleanFormatting, applyLink, applyInlineStyle, insertText, italic, strikethrough, subscript, superscript, underline, removeLink, link, selectAll, isAligned, alignCenterRules, alignBlocks, alignRemoveRules, alignJustifyRules, alignLeftRules, alignRightRules, formatBlockElements, getHtml, indent, insertImage, toggleOrderedList, toggleUnorderedList, outdent, redo, setHtml, undo, hasMark, activeNode, canIndentAsListItem, canBeIndented, indentRules, hasNode, canOutdentAsListItem, outdentRules, getActiveMarks, expandSelection, addColumnBefore, addColumnAfter, addRowBefore, addRowAfter, deleteRow, deleteColumn, mergeCells, splitCell, deleteTable, getNodeFromSelection, getMark, getSelectionText, parseContent, Plugin, PluginKey, history, keymap, buildListKeymap, buildKeymap, baseKeymap, gapCursor, imageResizing, placeholder, EditorState, EditorView, hasSameMarkup, pasteCleanup, removeComments, sanitize, removeAttribute, sanitizeStyleAttr, sanitizeClassAttr } from '@progress/kendo-editor-common';
export { Schema, EditorState, Plugin, PluginKey, Transaction, EditorView, Decoration, DecorationSet, NodeType, Node, MarkType, Mark, InputRule, inputRules, wrappingInputRule, textblockTypeInputRule, keymap, baseKeymap, history, dropCursor, gapCursor, tableNodes, getSelectionText } from '@progress/kendo-editor-common';
import { validatePackage } from '@progress/kendo-licensing';
import { LocalizationService, L10N_PREFIX, RTL, MessageService, ComponentMessages } from '@progress/kendo-angular-l10n';
import { CommonModule } from '@angular/common';
import { DropDownListComponent, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ColorPickerComponent, ColorPickerModule, NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { PopupService } from '@progress/kendo-angular-popup';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-editor',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1638198421,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * @hidden
 */
const hasAttrs = (attrs, exclude) => {
    for (let attr in attrs) {
        if (attr && attrs[attr] !== null && attr !== exclude) {
            return true;
        }
    }
    return false;
};
/**
 * @hidden
 */
const getAttrs = (attrs, exclude) => {
    const result = {};
    for (let attr in attrs) {
        if (attr && attrs[attr] !== null && attr !== exclude) {
            result[attr] = attrs[attr];
        }
    }
    return result;
};
/**
 * @hidden
 */
const getAttributes = (dom) => {
    const result = {};
    let attributes = dom.attributes, attr;
    for (let i = 0; i < attributes.length; i++) {
        attr = attributes[i];
        result[attr.name] = attr.value;
    }
    return result;
};
/**
 * @hidden
 */
const serializeDOMAttrs = (el) => Array.from(el.attributes)
    .reduce((acc, curr) => Object.assign({}, acc, { [curr.name]: curr.value }), {});
/**
 * @hidden
 */
const commonAttributes = () => {
    return Object.assign({}, createDefaultAttributes(['class', 'id', 'style']));
};
/**
 * @hidden
 */
const createDefaultAttributes = (attrs = []) => {
    return Object.assign({}, attrs.reduce((acc, curr) => (Object.assign({}, acc, { [curr]: { default: null } })), {}));
};
/**
 * @hidden
 */
const hole = 0;
/**
 * @hidden
 */
const isSchemaNode = (schemaNodeName) => (node) => node.type.name === schemaNodeName;
/**
 * @hidden
 */
const isTable = isSchemaNode('table');
/**
 * @hidden
 */
const isTableBody = isSchemaNode('table_body');
/**
 * @hidden
 */
const isTableHead = isSchemaNode('table_head');
/**
 * @hidden
 */
const isTableFoot = isSchemaNode('table_foot');
/**
 * @hidden
 */
const isTableRow = isSchemaNode('table_row');
/**
 * @hidden
 */
const isTableCell = isSchemaNode('table_cell');
/**
 * @hidden
 */
const isTableHeaderCell = isSchemaNode('table_header');

const createSemanticNode = (tagName) => ({
    // Uncaught SyntaxError: Mixing inline and block content (in content expression '(block | inline)*')
    // content: '(block | inline)*',
    content: 'block*',
    group: 'block',
    attrs: Object.assign({}, commonAttributes()),
    parseDOM: [{
            tag: tagName,
            getAttrs: getAttributes
        }],
    toDOM: node => hasAttrs(node.attrs) ? [tagName, getAttrs(node.attrs), hole] : [tagName, hole]
});
/**
 * @hidden
 */
const semanticTagNames = ['article', 'main', 'nav', 'header', 'footer', 'aside', 'section'];
const ɵ1 = (acc, curr) => Object.assign(acc, { [curr]: createSemanticNode(curr) });
/**
 * @hidden
 */
const semanticNodes = semanticTagNames.reduce(ɵ1, {});

/**
 * @hidden
 */
function outerWidth(element) {
    let width = element.offsetWidth;
    const style = getComputedStyle(element);
    width += parseFloat(style.marginLeft) || 0 + parseFloat(style.marginRight) || 0;
    return width;
}
/**
 * @hidden
 */
const removeEntries = (obj, predicate) => Object.keys(obj)
    .filter(key => predicate(key))
    .reduce((acc, curr) => Object.assign(acc, { [curr]: obj[curr] }), {});
/**
 * @hidden
 */
const removeEmptyEntries = (obj) => {
    const predicate = key => obj[key] !== null && obj[key] !== undefined && obj[key] !== '';
    return removeEntries(obj, predicate);
};
/**
 * @hidden
 */
const isNullOrUndefined = (value) => value === undefined || value === null;
/**
 * @hidden
 */
const isPresent = (value) => !isNullOrUndefined(value);
/**
 * @hidden
 */
const detectIE = () => {
    if (!isDocumentAvailable()) {
        return false;
    }
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    return msie > 0 || trident > 0;
};
/**
 * @hidden
 */
const safeString = (value) => (isNullOrUndefined(value) ? '' : value.toString());
/**
 * @hidden
 */
const first = (arr) => arr[0];
/**
 * @hidden
 */
const last = (arr) => arr[arr.length - 1];
/**
 * @hidden
 */
const split = (splitter) => (value) => value.split(splitter);
/**
 * @hidden
 */
const trim = (value) => value.trim();
/**
 * @hidden
 */
const filter$1 = (predicate) => (arr) => arr.filter(predicate);
/**
 * @hidden
 */
const getUniqueStyleValues = (style, cssStyle) => {
    if (style.hasNodesWithoutMarks) {
        return '';
    }
    const uniqueMarkValues = style.marks
        .filter(m => m.type.name === 'style')
        .map(m => m.attrs.style)
        .map(safeString)
        .map(split(';'))
        .map(filter$1((m) => m.includes(cssStyle)))
        // guards against empty array
        .map((cssStyleValues) => (cssStyleValues.length !== 0 ? cssStyleValues : [`${cssStyle}: INVALID`]))
        .map(first)
        .map(split(':'))
        .map(last)
        .map(trim)
        .reduce((acc, curr) => (acc.indexOf(curr) > -1 ? acc : [...acc, curr]), []);
    if (uniqueMarkValues.indexOf('INVALID') > -1 || uniqueMarkValues.length !== 1) {
        return '';
    }
    return uniqueMarkValues[0];
};
/**
 * @hidden
 */
const conditionallyExecute = (fn) => (condition) => (param) => (condition ? fn(param) : param);
/**
 * @hidden
 */
const pipe = (...fns) => x => fns.reduce((y, f) => f(y), x);

const setCellAttrs = nodeAttributes => {
    let attrs = Object.assign({}, nodeAttributes);
    if (nodeAttributes.colspan !== 1) {
        attrs.colspan = nodeAttributes.colspan;
    }
    else {
        attrs.colspan = null;
    }
    if (nodeAttributes.rowspan !== 1) {
        attrs.rowspan = nodeAttributes.rowspan;
    }
    else {
        attrs.rowspan = null;
    }
    return removeEmptyEntries(attrs);
};
const ɵ1$1 = node => ['table', removeEmptyEntries(node.attrs), 0], ɵ2 = node => ['tr', removeEmptyEntries(node.attrs), 0], ɵ3 = node => ['td', setCellAttrs(node.attrs), 0], ɵ4 = node => ['th', setCellAttrs(node.attrs), 0], ɵ5 = node => ['tbody', removeEmptyEntries(node.attrs), 0], ɵ6 = node => ['tfoot', removeEmptyEntries(node.attrs), 0], ɵ7 = node => ['thead', removeEmptyEntries(node.attrs), 0], ɵ8 = node => ['col', removeEmptyEntries(node.attrs)], ɵ9 = node => ['colgroup', removeEmptyEntries(node.attrs), 0];
/**
 * @hidden
 */
const tableNodes = {
    // all table node names must start with table - this is needed for the commands to work properly
    table: {
        attrs: Object.assign({}, commonAttributes(), createDefaultAttributes(['cellspacing', 'cellpadding'])),
        content: '(table_body | table_head | table_foot | table_colgroup)+',
        tableRole: 'table',
        isolating: true,
        group: 'block',
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'table'
            }
        ],
        toDOM: ɵ1$1
    },
    table_row: {
        content: '(table_cell | table_header)*',
        attrs: Object.assign({}, commonAttributes()),
        tableRole: 'row',
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'tr'
            }
        ],
        toDOM: ɵ2
    },
    table_cell: {
        content: 'block+',
        attrs: Object.assign({}, commonAttributes(), createDefaultAttributes(['headers']), { 
            // this is needed for the commands
            colspan: { default: 1 }, rowspan: { default: 1 } }),
        tableRole: 'cell',
        isolating: true,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'td'
            }
        ],
        toDOM: ɵ3
    },
    table_header: {
        content: 'block+',
        attrs: Object.assign({}, commonAttributes(), createDefaultAttributes(['headers', 'abbr', 'scope']), { colspan: { default: 1 }, rowspan: { default: 1 } }),
        tableRole: 'header_cell',
        isolating: true,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'th'
            }
        ],
        toDOM: ɵ4
    },
    table_body: {
        attrs: Object.assign({}, commonAttributes()),
        content: 'table_row+',
        isolating: true,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'tbody'
            }
        ],
        toDOM: ɵ5
    },
    table_foot: {
        attrs: Object.assign({}, commonAttributes()),
        content: 'table_row+',
        isolating: true,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'tfoot'
            }
        ],
        toDOM: ɵ6
    },
    table_head: {
        attrs: Object.assign({}, commonAttributes()),
        content: 'table_row+',
        isolating: true,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'thead'
            }
        ],
        toDOM: ɵ7
    },
    table_col: {
        attrs: Object.assign({}, commonAttributes(), createDefaultAttributes(['span'])),
        selectable: false,
        atom: true,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'col'
            }
        ],
        toDOM: ɵ8
    },
    table_colgroup: {
        attrs: Object.assign({}, commonAttributes(), createDefaultAttributes(['span'])),
        content: 'table_col*',
        selectable: false,
        parseDOM: [
            {
                getAttrs: serializeDOMAttrs,
                tag: 'colgroup'
            }
        ],
        toDOM: ɵ9
    }
};

/**
 * @hidden
 */
const marks$1 = marks;
const nodes$1 = Object.assign(nodes, tableNodes, semanticNodes);
/**
 * @hidden
 */
const schema = new Schema({
    marks: marks$1,
    nodes: Object.assign({}, nodes$1, { blockquote: Object.assign({}, nodes$1.blockquote, { content: 'inline*' }) })
});

// Because working with row and column-spanning cells is not quite
// trivial, this code builds up a descriptive structure for a given
// table node. The structures are cached with the (persistent) table
// nodes as key, so that they only have to be recomputed when the
// content of the table changes.
//
// This does mean that they have to store table-relative, not
// document-relative positions. So code that uses them will typically
// compute the start position of the table and offset positions passed
// to or gotten from this structure by that amount.
let readFromCache;
let addToCache;
// Prefer using a weak map to cache table maps. Fall back on a
// fixed-size cache if that's not supported.
if (typeof WeakMap !== 'undefined') {
    let cache = new WeakMap();
    readFromCache = key => cache.get(key);
    addToCache = (key, value) => {
        cache.set(key, value);
        return value;
    };
}
else {
    let cache = [];
    let cacheSize = 10;
    let cachePos = 0;
    readFromCache = key => {
        for (let i = 0; i < cache.length; i += 2) {
            if (cache[i] === key) {
                return cache[i + 1];
            }
        }
    };
    addToCache = (key, value) => {
        if (cachePos === cacheSize) {
            cachePos = 0;
        }
        cache[cachePos++] = key;
        return (cache[cachePos++] = value);
    };
}
const findWidth = (table) => {
    let width = -1;
    let hasRowSpan = false;
    for (let row = 0; row < table.childCount; row++) {
        const rowNode = table.child(row);
        let rowWidth = 0;
        if (hasRowSpan) {
            for (let j = 0; j < row; j++) {
                const prevRow = table.child(j);
                for (let i = 0; i < prevRow.childCount; i++) {
                    const cell = prevRow.child(i);
                    if (j + cell.attrs.rowspan > row) {
                        rowWidth += cell.attrs.colspan;
                    }
                }
            }
        }
        // check if the cell spans accross multiple columns
        for (let i = 0; i < rowNode.childCount; i++) {
            const cell = rowNode.child(i);
            rowWidth += cell.attrs.colspan;
            if (cell.attrs.rowspan > 1) {
                hasRowSpan = true;
            }
        }
        if (width === -1) {
            width = rowWidth;
        }
        else if (width !== rowWidth) {
            width = Math.max(width, rowWidth);
        }
    }
    return width;
};
const freshColWidth = attrs => {
    if (attrs.colwidth) {
        return attrs.colwidth.slice();
    }
    const result = [];
    for (let i = 0; i < attrs.colspan; i++) {
        result.push(0);
    }
    return result;
};
const findBadColWidths = (map$$1, colWidths, table) => {
    if (!map$$1.problems) {
        map$$1.problems = [];
    }
    for (let i = 0, seen = {}; i < map$$1.map.length; i++) {
        const pos = map$$1.map[i];
        if (seen[pos]) {
            continue;
        }
        seen[pos] = true;
        let node = table.nodeAt(pos);
        let updated = null;
        for (let j = 0; j < node.attrs.colspan; j++) {
            const col = (i + j) % map$$1.width;
            const colWidth = colWidths[col * 2];
            if (colWidth != null && (!node.attrs.colwidth || node.attrs.colwidth[j] !== colWidth)) {
                (updated || (updated = freshColWidth(node.attrs)))[j] = colWidth;
            }
        }
        if (updated) {
            map$$1.problems.unshift({ type: 'colwidth mismatch', pos, colwidth: updated });
        }
    }
};
// Compute a table map.
const computeMap = tableNode => {
    if (!isTable(tableNode) && !isTableBody(tableNode) && !isTableHead(tableNode) && !isTableFoot(tableNode)) {
        throw new RangeError('Not a table node: ' + tableNode.type.name);
    }
    const width = findWidth(tableNode);
    const height = tableNode.childCount;
    const map$$1 = [];
    let mapPos = 0;
    let problems = null;
    const colWidths = [];
    for (let i = 0, e = width * height; i < e; i++) {
        map$$1[i] = 0;
    }
    for (let row = 0, pos = 0; row < height; row++) {
        const rowNode = tableNode.child(row);
        pos++;
        for (let i = 0;; i++) {
            while (mapPos < map$$1.length && map$$1[mapPos] !== 0) {
                mapPos++;
            }
            if (i === rowNode.childCount) {
                break;
            }
            const cellNode = rowNode.child(i);
            const { colspan, rowspan, colwidth } = cellNode.attrs;
            for (let h = 0; h < rowspan; h++) {
                if (h + row >= height) {
                    (problems || (problems = [])).push({ type: 'overlong_rowspan', pos, n: rowspan - h });
                    break;
                }
                const start = mapPos + h * width;
                for (let w = 0; w < colspan; w++) {
                    if (map$$1[start + w] === 0) {
                        map$$1[start + w] = pos;
                    }
                    else {
                        (problems || (problems = [])).push({ type: 'collision', row, pos, n: colspan - w });
                    }
                    const colW = colwidth && colwidth[w];
                    if (colW) {
                        const widthIndex = ((start + w) % width) * 2, prev = colWidths[widthIndex];
                        if (prev == null || (prev !== colW && colWidths[widthIndex + 1] === 1)) {
                            colWidths[widthIndex] = colW;
                            colWidths[widthIndex + 1] = 1;
                        }
                        else if (prev === colW) {
                            colWidths[widthIndex + 1]++;
                        }
                    }
                }
            }
            mapPos += colspan;
            pos += cellNode.nodeSize;
        }
        const expectedPos = (row + 1) * width;
        let missing = 0;
        while (mapPos < expectedPos) {
            if (map$$1[mapPos++] === 0) {
                missing++;
            }
        }
        if (missing) {
            (problems || (problems = [])).push({ type: 'missing', row, n: missing });
        }
        pos++;
    }
    const tableMap = new TableMap(width, height, map$$1, problems);
    let badWidths = false;
    // For columns that have defined widths, but whose widths disagree
    // between rows, fix up the cells whose width doesn't match the
    // computed one.
    for (let i = 0; !badWidths && i < colWidths.length; i += 2) {
        if (colWidths[i] != null && colWidths[i + 1] < height) {
            badWidths = true;
        }
    }
    if (badWidths) {
        findBadColWidths(tableMap, colWidths, tableNode);
    }
    return tableMap;
};
/**
 * @hidden
 */
class Rect {
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }
}
/**
 * @hidden
 */
class TableMap {
    // :: (Node) → TableMap
    // Find the table map for the given table node.
    static get(table) {
        return readFromCache(table) || addToCache(table, computeMap(table));
    }
    constructor(width, height, map$$1, problems) {
        // :: number The width of the table
        this.width = width;
        // :: number The table's height
        this.height = height;
        // :: [number] A width * height array with the start position of
        // the cell covering that part of the table in each slot
        this.map = map$$1;
        // An optional array of problems (cell overlap or non-rectangular
        // shape) for the table, used by the table normalizer.
        this.problems = problems;
    }
    // :: (number) → Rect
    // Find the dimensions of the cell at the given position.
    findCell(pos) {
        for (let i = 0; i < this.map.length; i++) {
            const curPos = this.map[i];
            if (curPos !== pos) {
                continue;
            }
            const left = i % this.width;
            const top = Math.floor(i / this.width);
            let right = left + 1;
            let bottom = top + 1;
            for (let j = 1; right < this.width && this.map[i + j] === curPos; j++) {
                right++;
            }
            for (let j = 1; bottom < this.height && this.map[i + this.width * j] === curPos; j++) {
                bottom++;
            }
            return new Rect(left, top, right, bottom);
        }
        throw new RangeError('No cell with offset ' + pos + ' found');
    }
    // :: (number) → number
    // Find the left side of the cell at the given position.
    colCount(pos) {
        for (let i = 0; i < this.map.length; i++) {
            if (this.map[i] === pos) {
                return i % this.width;
            }
        }
        throw new RangeError('No cell with offset ' + pos + ' found');
    }
    // :: (number, string, number) → ?number
    // Find the next cell in the given direction, starting from the cell
    // at `pos`, if any.
    nextCell(pos, axis, dir) {
        const { left, right, top, bottom } = this.findCell(pos);
        if (axis === 'horiz') {
            if (dir < 0 ? left === 0 : right === this.width) {
                return null;
            }
            return this.map[top * this.width + (dir < 0 ? left - 1 : right)];
        }
        else {
            if (dir < 0 ? top === 0 : bottom === this.height) {
                return null;
            }
            return this.map[left + this.width * (dir < 0 ? top - 1 : bottom)];
        }
    }
    // :: (number, number) → Rect
    // Get the rectangle spanning the two given cells.
    rectBetween(a, b) {
        const { left: leftA, right: rightA, top: topA, bottom: bottomA } = this.findCell(a);
        const { left: leftB, right: rightB, top: topB, bottom: bottomB } = this.findCell(b);
        return new Rect(Math.min(leftA, leftB), Math.min(topA, topB), Math.max(rightA, rightB), Math.max(bottomA, bottomB));
    }
    // :: (Rect) → [number]
    // Return the position of all cells that have the top left corner in
    // the given rectangle.
    cellsInRect(rect) {
        const result = [];
        const seen = {};
        for (let row = rect.top; row < rect.bottom; row++) {
            for (let col = rect.left; col < rect.right; col++) {
                const index = row * this.width + col, pos = this.map[index];
                if (seen[pos]) {
                    continue;
                }
                seen[pos] = true;
                if ((col !== rect.left || !col || this.map[index - 1] !== pos) &&
                    (row !== rect.top || !row || this.map[index - this.width] !== pos)) {
                    result.push(pos);
                }
            }
        }
        return result;
    }
    // :: (number, number, Node) → number
    // Return the position at which the cell at the given row and column
    // starts, or would start, if a cell started there.
    positionAt(row, col, table) {
        for (let i = 0, rowStart = 0;; i++) {
            const rowEnd = rowStart + table.child(i).nodeSize;
            if (i === row) {
                let index = col + row * this.width, rowEndIndex = (row + 1) * this.width;
                // Skip past cells from previous rows (via rowspan)
                while (index < rowEndIndex && this.map[index] < rowStart) {
                    index++;
                }
                return index === rowEndIndex ? rowEnd - 1 : this.map[index];
            }
            rowStart = rowEnd;
        }
    }
}

/**
 * @hidden
 */
// ::- A [`Selection`](http://prosemirror.net/docs/ref/#state.Selection)
// subclass that represents a cell selection spanning part of a table.
// With the plugin enabled, these will be created when the user
// selects across cells, and will be drawn by giving selected cells a
// `selectedCell` CSS class.
class CellSelection extends Selection {
    // :: (ResolvedPos, ?ResolvedPos) → CellSelection
    // Returns the smallest column selection that covers the given anchor
    // and head cell.
    static colSelection($anchorCell, $headCell = $anchorCell) {
        let map$$1 = TableMap.get($anchorCell.node(-1));
        let start = $anchorCell.start(-1);
        let anchorRect = map$$1.findCell($anchorCell.pos - start);
        let headRect = map$$1.findCell($headCell.pos - start);
        let doc = $anchorCell.node(0);
        if (anchorRect.top <= headRect.top) {
            if (anchorRect.top > 0) {
                $anchorCell = doc.resolve(start + map$$1.map[anchorRect.left]);
            }
            if (headRect.bottom < map$$1.height) {
                $headCell = doc.resolve(start + map$$1.map[map$$1.width * (map$$1.height - 1) + headRect.right - 1]);
            }
        }
        else {
            if (headRect.top > 0) {
                $headCell = doc.resolve(start + map$$1.map[headRect.left]);
            }
            if (anchorRect.bottom < map$$1.height) {
                $anchorCell = doc.resolve(start + map$$1.map[map$$1.width * (map$$1.height - 1) + anchorRect.right - 1]);
            }
        }
        return new CellSelection($anchorCell, $headCell);
    }
    // :: (ResolvedPos, ?ResolvedPos) → CellSelection
    // Returns the smallest row selection that covers the given anchor
    // and head cell.
    static rowSelection($anchorCell, $headCell = $anchorCell) {
        let map$$1 = TableMap.get($anchorCell.node(-1));
        let start = $anchorCell.start(-1);
        let anchorRect = map$$1.findCell($anchorCell.pos - start);
        let headRect = map$$1.findCell($headCell.pos - start);
        let doc = $anchorCell.node(0);
        if (anchorRect.left <= headRect.left) {
            if (anchorRect.left > 0) {
                $anchorCell = doc.resolve(start + map$$1.map[anchorRect.top * map$$1.width]);
            }
            if (headRect.right < map$$1.width) {
                $headCell = doc.resolve(start + map$$1.map[map$$1.width * (headRect.top + 1) - 1]);
            }
        }
        else {
            if (headRect.left > 0) {
                $headCell = doc.resolve(start + map$$1.map[headRect.top * map$$1.width]);
            }
            if (anchorRect.right < map$$1.width) {
                $anchorCell = doc.resolve(start + map$$1.map[map$$1.width * (anchorRect.top + 1) - 1]);
            }
        }
        return new CellSelection($anchorCell, $headCell);
    }
    static fromJSON(doc, json) {
        return new CellSelection(doc.resolve(json.anchor), doc.resolve(json.head));
    }
    // :: (Node, number, ?number) → CellSelection
    static create(doc, anchorCell, headCell = anchorCell) {
        return new CellSelection(doc.resolve(anchorCell), doc.resolve(headCell));
    }
    // :: (ResolvedPos, ?ResolvedPos)
    // A table selection is identified by its anchor and head cells. The
    // positions given to this constructor should point _before_ two
    // cells in the same table. They may be the same, to select a single
    // cell.
    constructor($anchorCell, $headCell = $anchorCell) {
        let table = $anchorCell.node(-1), map$$1 = TableMap.get(table), start = $anchorCell.start(-1);
        let rect = map$$1.rectBetween($anchorCell.pos - start, $headCell.pos - start);
        let doc = $anchorCell.node(0);
        let cells = map$$1.cellsInRect(rect).filter(p => p !== $headCell.pos - start);
        // Make the head cell the first range, so that it counts as the
        // primary part of the selection
        cells.unshift($headCell.pos - start);
        let ranges = cells.map(pos => {
            let cell = table.nodeAt(pos), from = pos + start + 1;
            return new SelectionRange(doc.resolve(from), doc.resolve(from + cell.content.size));
        });
        super(ranges[0].$from, ranges[0].$to, ranges);
        // :: ResolvedPos
        // A resolved position pointing _in front of_ the anchor cell (the one
        // that doesn't move when extending the selection).
        this.$anchorCell = $anchorCell;
        // :: ResolvedPos
        // A resolved position pointing in front of the head cell (the one
        // moves when extending the selection).
        this.$headCell = $headCell;
    }
    map(doc, mapping) {
        let $anchorCell = doc.resolve(mapping.map(this.$anchorCell.pos));
        let $headCell = doc.resolve(mapping.map(this.$headCell.pos));
        if (pointsAtCell($anchorCell) && pointsAtCell($headCell) && inSameTable($anchorCell, $headCell)) {
            let tableChanged = this.$anchorCell.node(-1) !== $anchorCell.node(-1);
            if (tableChanged && this.isRowSelection()) {
                return CellSelection.rowSelection($anchorCell, $headCell);
            }
            else {
                if (tableChanged && this.isColSelection()) {
                    return CellSelection.colSelection($anchorCell, $headCell);
                }
                else {
                    return new CellSelection($anchorCell, $headCell);
                }
            }
        }
        return TextSelection.between($anchorCell, $headCell);
    }
    // :: () → Slice
    // Returns a rectangular slice of table rows containing the selected
    // cells.
    content() {
        let table = this.$anchorCell.node(-1), map$$1 = TableMap.get(table), start = this.$anchorCell.start(-1);
        let rect = map$$1.rectBetween(this.$anchorCell.pos - start, this.$headCell.pos - start);
        let seen = {}, rows = [];
        for (let row = rect.top; row < rect.bottom; row++) {
            let rowContent = [];
            for (let index = row * map$$1.width + rect.left, col = rect.left; col < rect.right; col++, index++) {
                let pos = map$$1.map[index];
                if (!seen[pos]) {
                    seen[pos] = true;
                    let cellRect = map$$1.findCell(pos), cell = table.nodeAt(pos);
                    let extraLeft = rect.left - cellRect.left, extraRight = cellRect.right - rect.right;
                    if (extraLeft > 0 || extraRight > 0) {
                        let attrs = cell.attrs;
                        if (extraLeft > 0) {
                            attrs = removeColSpan(attrs, 0, extraLeft);
                        }
                        if (extraRight > 0) {
                            attrs = removeColSpan(attrs, attrs.colspan - extraRight, extraRight);
                        }
                        if (cellRect.left < rect.left) {
                            cell = cell.type.createAndFill(attrs);
                        }
                        else {
                            cell = cell.type.create(attrs, cell.content);
                        }
                    }
                    if (cellRect.top < rect.top || cellRect.bottom > rect.bottom) {
                        let attrs = setAttr(cell.attrs, 'rowspan', Math.min(cellRect.bottom, rect.bottom) - Math.max(cellRect.top, rect.top));
                        if (cellRect.top < rect.top) {
                            cell = cell.type.createAndFill(attrs);
                        }
                        else {
                            cell = cell.type.create(attrs, cell.content);
                        }
                    }
                    rowContent.push(cell);
                }
            }
            rows.push(table.child(row).copy(Fragment.from(rowContent)));
        }
        const fragment = this.isColSelection() && this.isRowSelection() ? table : rows;
        return new Slice(Fragment.from(fragment), 1, 1);
    }
    replace(tr, content = Slice.empty) {
        let mapFrom = tr.steps.length;
        let ranges = this.ranges;
        for (let i = 0; i < ranges.length; i++) {
            let { $from, $to } = ranges[i];
            let mapping = tr.mapping.slice(mapFrom);
            tr.replace(mapping.map($from.pos), mapping.map($to.pos), i ? Slice.empty : content);
        }
        let sel = Selection.findFrom(tr.doc.resolve(tr.mapping.slice(mapFrom).map(this.to)), -1);
        if (sel) {
            tr.setSelection(sel);
        }
    }
    replaceWith(tr, node) {
        this.replace(tr, new Slice(Fragment.from(node), 0, 0));
    }
    forEachCell(f) {
        let table = this.$anchorCell.node(-1);
        let map$$1 = TableMap.get(table);
        let start = this.$anchorCell.start(-1);
        let cells = map$$1.cellsInRect(map$$1.rectBetween(this.$anchorCell.pos - start, this.$headCell.pos - start));
        for (let i = 0; i < cells.length; i++) {
            f(table.nodeAt(cells[i]), start + cells[i]);
        }
    }
    // :: () → bool
    // True if this selection goes all the way from the top to the
    // bottom of the table.
    isColSelection() {
        let anchorTop = this.$anchorCell.index(-1), headTop = this.$headCell.index(-1);
        if (Math.min(anchorTop, headTop) > 0) {
            return false;
        }
        let anchorBot = anchorTop + this.$anchorCell.nodeAfter.attrs.rowspan;
        let headBot = headTop + this.$headCell.nodeAfter.attrs.rowspan;
        return Math.max(anchorBot, headBot) === this.$headCell.node(-1).childCount;
    }
    // :: () → bool
    // True if this selection goes all the way from the left to the
    // right of the table.
    isRowSelection() {
        let map$$1 = TableMap.get(this.$anchorCell.node(-1));
        let start = this.$anchorCell.start(-1);
        let anchorLeft = map$$1.colCount(this.$anchorCell.pos - start);
        let headLeft = map$$1.colCount(this.$headCell.pos - start);
        if (Math.min(anchorLeft, headLeft) > 0) {
            return false;
        }
        let anchorRight = anchorLeft + this.$anchorCell.nodeAfter.attrs.colspan;
        let headRight = headLeft + this.$headCell.nodeAfter.attrs.colspan;
        return Math.max(anchorRight, headRight) === map$$1.width;
    }
    eq(other) {
        return (other instanceof CellSelection && other.$anchorCell.pos === this.$anchorCell.pos && other.$headCell.pos === this.$headCell.pos);
    }
    toJSON() {
        return { type: 'cell', anchor: this.$anchorCell.pos, head: this.$headCell.pos };
    }
    getBookmark() {
        return new CellBookmark(this.$anchorCell.pos, this.$headCell.pos);
    }
}
CellSelection.prototype.visible = false;
Selection.jsonID('table_cell', CellSelection);
class CellBookmark {
    constructor(anchor, head) {
        this.anchor = anchor;
        this.head = head;
    }
    map(mapping) {
        return new CellBookmark(mapping.map(this.anchor), mapping.map(this.head));
    }
    resolve(doc) {
        let $anchorCell = doc.resolve(this.anchor), $headCell = doc.resolve(this.head);
        if ($anchorCell.parent.type.spec.tableRole === 'row' &&
            $headCell.parent.type.spec.tableRole === 'row' &&
            $anchorCell.index() < $anchorCell.parent.childCount &&
            $headCell.index() < $headCell.parent.childCount &&
            inSameTable($anchorCell, $headCell)) {
            return new CellSelection($anchorCell, $headCell);
        }
        else {
            return Selection.near($headCell, 1);
        }
    }
}

/**
 * @hidden
 */
const findParentNodeClosestToPos = ($pos, predicate) => {
    for (let i = $pos.depth; i > 0; i--) {
        const node = $pos.node(i);
        if (predicate(node)) {
            return {
                start: $pos.start(i),
                pos: i > 0 ? $pos.before(i) : 0,
                depth: i,
                node
            };
        }
    }
};
/**
 * @hidden
 */
const createTable = (nodes$$1, rows, columns) => {
    const { table, table_body, table_row, table_cell } = nodes$$1;
    const tableRows = [];
    let cells;
    for (let r = 0; r < rows + 1; r++) {
        cells = [];
        for (let c = 0; c < columns + 1; c++) {
            cells.push(table_cell.createAndFill());
        }
        tableRows.push(table_row.createAndFill(undefined, cells));
    }
    const body = table_body.createAndFill(undefined, tableRows);
    return table.createAndFill(undefined, body);
};
/**
 * @hidden
 */
const isInTable = (state) => {
    const $head = state.selection.$head;
    for (let d = $head.depth; d > 0; d--) {
        if (isTableRow($head.node(d))) {
            return true;
        }
    }
    return false;
};
/**
 * @hidden
 */
const setAttr = (attrs, name, value) => {
    const result = {};
    // tslint:disable-next-line
    for (const prop in attrs) {
        result[prop] = attrs[prop];
    }
    result[name] = value;
    return result;
};
const cellNear = ($pos) => {
    for (let after = $pos.nodeAfter, pos = $pos.pos; after; after = after.firstChild, pos++) {
        const role = after.type.spec.tableRole;
        if (role === 'cell' || role === 'header_cell') {
            return $pos.doc.resolve(pos);
        }
    }
    for (let before = $pos.nodeBefore, pos = $pos.pos; before; before = before.lastChild, pos--) {
        const role = before.type.spec.tableRole;
        if (role === 'cell' || role === 'header_cell') {
            return $pos.doc.resolve(pos - before.nodeSize);
        }
    }
};
const cellAround = ($pos) => {
    for (let d = $pos.depth - 1; d > 0; d--) {
        if (isTableRow($pos.node(d))) {
            return $pos.node(0).resolve($pos.before(d + 1));
        }
    }
    return null;
};
const selectionCell = (sel) => {
    if (sel instanceof CellSelection && sel.$anchorCell) {
        return sel.$anchorCell.pos > sel.$headCell.pos ? sel.$anchorCell : sel.$headCell;
    }
    else if (sel instanceof NodeSelection && sel.node && isTableCell(sel.node)) {
        return sel.$anchor;
    }
    return cellAround(sel.$head) || cellNear(sel.$head);
};
/**
 * @hidden
 */
// Helper to get the selected rectangle in a table, if any. Adds table
// map, table node, and table start offset to the object for
// convenience.
const selectedRect = (sel) => {
    // create arr of rect for each table section
    // there has to be a rect for thead, tbody and tfoot(if each exists)
    // and NOT for colgroups
    const $pos = selectionCell(sel);
    const table = $pos.node(-1);
    const tableStart = $pos.start(-1);
    const map$$1 = TableMap.get(table);
    const rect = sel instanceof CellSelection
        ? map$$1.rectBetween(sel.$anchorCell.pos - tableStart, sel.$headCell.pos - tableStart)
        : map$$1.findCell($pos.pos - tableStart);
    rect.tableStart = tableStart;
    rect.map = map$$1;
    rect.table = table;
    return rect;
};
/**
 * @hidden
 */
// this has to be removed since the schema should not have roles inside NodeSpec.
const tableNodeTypes = (schema) => {
    let result = schema.cached.tableNodeTypes;
    if (!result) {
        result = schema.cached.tableNodeTypes = {};
        // tslint:disable-next-line
        for (const name in schema.nodes) {
            const type = schema.nodes[name];
            const role = type.spec.tableRole;
            if (role) {
                result[role] = type;
            }
        }
    }
    return result;
};
/**
 * @hidden
 */
const rowIsHeader = (map$$1, table, row) => {
    const headerCell = tableNodeTypes(table.type.schema).header_cell;
    for (let col = 0; col < map$$1.width; col++) {
        if (table.nodeAt(map$$1.map[col + row * map$$1.width]).type !== headerCell) {
            return false;
        }
    }
    return true;
};
/**
 * @hidden
 */
const addColSpan = (attrs, pos, n = 1) => {
    const result = setAttr(attrs, 'colspan', attrs.colspan + n);
    if (result.colwidth) {
        result.colwidth = result.colwidth.slice();
        for (let i = 0; i < n; i++) {
            result.colwidth.splice(pos, 0, 0);
        }
    }
    return result;
};
/**
 * @hidden
 */
const removeColSpan = (attrs, pos, n = 1) => {
    const result = setAttr(attrs, 'colspan', attrs.colspan - n);
    if (result.colwidth) {
        result.colwidth = result.colwidth.slice();
        result.colwidth.splice(pos, n);
        if (!result.colwidth.some(w => w > 0)) {
            result.colwidth = null;
        }
    }
    return result;
};
/**
 * @hidden
 */
const isCellEmpty = cell => {
    const c = cell.content;
    return c.childCount === 1 && c.firstChild.isTextblock && c.firstChild.childCount === 0;
};
const cellWrapping = ($pos) => {
    for (let d = $pos.depth; d > 0; d--) {
        // Sometimes the cell can be in the same depth.
        const role = $pos.node(d).type.spec.tableRole;
        if (role === 'cell' || role === 'header_cell') {
            return $pos.node(d);
        }
    }
    return null;
};
/**
 * @hidden
 */
// Split a selected cell, whose rowpan or colspan is greater than one,
// into smaller cells with the cell type (th, td) returned by getType function.
const splitCellWithType = getCellType => {
    return (state, dispatch) => {
        const sel = state.selection;
        let cellNode;
        let cellPos;
        if (!(sel instanceof CellSelection)) {
            cellNode = cellWrapping(sel.$from);
            if (!cellNode) {
                return false;
            }
            cellPos = cellAround(sel.$from).pos;
        }
        else {
            if (sel.$anchorCell.pos !== sel.$headCell.pos) {
                return false;
            }
            cellNode = sel.$anchorCell.nodeAfter;
            cellPos = sel.$anchorCell.pos;
        }
        if (cellNode.attrs.colspan === 1 && cellNode.attrs.rowspan === 1) {
            return false;
        }
        if (dispatch) {
            let baseAttrs = cellNode.attrs;
            const attrs = [];
            const colwidth = baseAttrs.colwidth;
            if (baseAttrs.rowspan > 1) {
                baseAttrs = setAttr(baseAttrs, 'rowspan', 1);
            }
            if (baseAttrs.colspan > 1) {
                baseAttrs = setAttr(baseAttrs, 'colspan', 1);
            }
            const rect = selectedRect(state);
            const tr = state.tr;
            for (let i = 0; i < rect.right - rect.left; i++) {
                attrs.push(colwidth ? setAttr(baseAttrs, 'colwidth', colwidth && colwidth[i] ? [colwidth[i]] : null) : baseAttrs);
            }
            let lastCell;
            for (let row = rect.top; row < rect.bottom; row++) {
                let pos = rect.map.positionAt(row, rect.left, rect.table);
                if (row === rect.top) {
                    pos += cellNode.nodeSize;
                }
                for (let col = rect.left, i = 0; col < rect.right; col++, i++) {
                    if (col === rect.left && row === rect.top) {
                        continue;
                    }
                    tr.insert((lastCell = tr.mapping.map(pos + rect.tableStart, 1)), getCellType({ node: cellNode, row, col }).createAndFill(attrs[i]));
                }
            }
            tr.setNodeMarkup(cellPos, getCellType({ node: cellNode, row: rect.top, col: rect.left }), attrs[0]);
            if (sel instanceof CellSelection) {
                tr.setSelection(new CellSelection(tr.doc.resolve(sel.$anchorCell.pos), lastCell && tr.doc.resolve(lastCell)));
            }
            dispatch(tr);
        }
        return true;
    };
};
/**
 * @hidden
 */
const pointsAtCell = ($pos) => {
    return isTableRow($pos.parent) && $pos.nodeAfter;
};
/**
 * @hidden
 */
const inSameTable = ($a, $b) => {
    return $a.depth === $b.depth && $a.pos >= $b.start(-1) && $a.pos <= $b.end(-1);
};
/**
 * @hidden
 */
const cellsOverlapRectangle = ({ width, height, map: map$$1 }, rect) => {
    let indexTop = rect.top * width + rect.left;
    let indexLeft = indexTop;
    let indexBottom = (rect.bottom - 1) * width + rect.left;
    let indexRight = indexTop + (rect.right - rect.left - 1);
    for (let i = rect.top; i < rect.bottom; i++) {
        if ((rect.left > 0 && map$$1[indexLeft] === map$$1[indexLeft - 1]) || (rect.right < width && map$$1[indexRight] === map$$1[indexRight + 1])) {
            return true;
        }
        indexLeft += width;
        indexRight += width;
    }
    for (let i = rect.left; i < rect.right; i++) {
        if ((rect.top > 0 && map$$1[indexTop] === map$$1[indexTop - width]) ||
            (rect.bottom < height && map$$1[indexBottom] === map$$1[indexBottom + width])) {
            return true;
        }
        indexTop++;
        indexBottom++;
    }
    return false;
};

/**
 * @hidden
 */
const insertTable = (attrs) => (state, dispatch) => {
    const newTable = createTable(state.schema.nodes, attrs.rows, attrs.cols);
    if (newTable) {
        insertNode(newTable, true)(state, dispatch);
    }
};
const addTableGroupColumn = (tr, { left, right }, col, mainTable, requiredGroup, doc) => {
    let refColumn = col > 0 ? -1 : 0;
    // not sure if this should be deleted
    // if (columnIsHeader(map, table, col + refColumn)) {
    //     refColumn = col === 0 || col === map.width ? null : 0;
    // }
    let rect;
    mainTable.node.descendants((tableChildNode, offset) => {
        if (requiredGroup(tableChildNode)) {
            let i = -1;
            tableChildNode.firstChild.descendants((childNode, pos) => {
                if (isTableCell(childNode) || isTableHeaderCell(childNode)) {
                    i = i + 1;
                    if (i === right || i === left) {
                        const selection = new Selection(doc.resolve(pos + offset + mainTable.start), doc.resolve(pos + offset + mainTable.start));
                        rect = selectedRect(selection);
                    }
                }
            });
        }
    });
    // Do nothing if thead, tfoot or tbody is missing
    if (!rect) {
        return tr;
    }
    for (let row = 0; row < rect.map.height; row++) {
        const index = row * rect.map.width + col;
        // If this position falls inside a col-spanning cell
        if (col > 0 && col < rect.map.width && rect.map.map[index - 1] === rect.map.map[index]) {
            const pos = rect.map.map[index];
            const cell = rect.table.nodeAt(pos);
            tr.setNodeMarkup(tr.mapping.map(rect.tableStart + pos), null, addColSpan(cell.attrs, col - rect.map.colCount(pos)));
            // Skip ahead if rowspan > 1
            row += cell.attrs.rowspan - 1;
        }
        else {
            const type = rect.table.nodeAt(rect.map.map[index + refColumn]).type;
            const pos = rect.map.positionAt(row, col, rect.table);
            tr.insert(tr.mapping.map(rect.tableStart + pos), type.createAndFill());
        }
    }
    return tr;
};
const removeTableGroupColumn = (tr, { right, left }, mainTable, requiredGroup, doc) => {
    let rect;
    mainTable.node.descendants((tableChildNode, offset) => {
        if (requiredGroup(tableChildNode)) {
            let i = -1;
            tableChildNode.firstChild.descendants((childNode, pos) => {
                if (isTableCell(childNode) || isTableHeaderCell(childNode)) {
                    i = i + 1;
                    if (i >= left && i < right) {
                        const resolvedPos = doc.resolve(pos + offset + mainTable.start + childNode.nodeSize);
                        const selection = new Selection(resolvedPos, resolvedPos);
                        rect = selectedRect(selection);
                    }
                }
            });
        }
    });
    // Do nothing if thead, tfoot or tbody is missing
    if (!rect) {
        return;
    }
    for (let i = rect.right - 1;; i--) {
        removeColumn(tr, rect, i);
        if (i === rect.left) {
            break;
        }
        rect.table = rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc;
        rect.map = TableMap.get(rect.table);
    }
    return;
};
const removeColumn = (tr, { map: map$$1, table, tableStart }, col) => {
    for (let row = 0; row < map$$1.height;) {
        const index = row * map$$1.width + col;
        const pos = map$$1.map[index];
        const cell = table.nodeAt(pos);
        // If this is part of a col-spanning cell
        if ((col > 0 && map$$1.map[index - 1] === pos) || (col < map$$1.width - 1 && map$$1.map[index + 1] === pos)) {
            tr.setNodeMarkup(tr.mapping.map(tableStart + pos), null, removeColSpan(cell.attrs, col - map$$1.colCount(pos)));
        }
        else {
            const start = tr.mapping.map(tableStart + pos);
            tr.delete(start, start + cell.nodeSize);
        }
        row += cell.attrs.rowspan;
    }
};
/**
 * @hidden
 */
// Command to add a column before the column with the selection.
const addColumnBefore$1 = (state, dispatch) => {
    if (!isInTable(state)) {
        return false;
    }
    if (dispatch) {
        const rect = selectedRect(state.selection);
        const mainTable = findParentNodeClosestToPos(state.selection.$anchor, isTable);
        const tHeadTr = addTableGroupColumn(state.tr, rect, rect.left, mainTable, isTableHead, state.doc);
        const tBodyTr = addTableGroupColumn(tHeadTr, rect, rect.left, mainTable, isTableBody, state.doc);
        const tFootTr = addTableGroupColumn(tBodyTr, rect, rect.left, mainTable, isTableFoot, state.doc);
        dispatch(tFootTr);
    }
    return true;
};
/**
 * @hidden
 */
// Command to add a column after the column with the selection.
const addColumnAfter$1 = (state, dispatch) => {
    if (!isInTable(state)) {
        return false;
    }
    if (dispatch) {
        const rect = selectedRect(state.selection);
        const mainTable = findParentNodeClosestToPos(state.selection.$anchor, isTable);
        const theadTr = addTableGroupColumn(state.tr, rect, rect.right, mainTable, isTableHead, state.doc);
        const tBodyTr = addTableGroupColumn(theadTr, rect, rect.right, mainTable, isTableBody, state.doc);
        const tFootTr = addTableGroupColumn(tBodyTr, rect, rect.right, mainTable, isTableFoot, state.doc);
        dispatch(tFootTr);
    }
    return true;
};
const addRow = (tr, { map: map$$1, tableStart, table }, row) => {
    let rowPos = tableStart;
    for (let i = 0; i < row; i++) {
        rowPos += table.child(i).nodeSize;
    }
    const cells = [];
    let refRow = row > 0 ? -1 : 0;
    if (rowIsHeader(map$$1, table, row + refRow)) {
        refRow = row === 0 || row === map$$1.height ? null : 0;
    }
    for (let col = 0, index = map$$1.width * row; col < map$$1.width; col++, index++) {
        // Covered by a rowspan cell
        if (row > 0 && row < map$$1.height && map$$1.map[index] === map$$1.map[index - map$$1.width]) {
            const pos = map$$1.map[index];
            const attrs = table.nodeAt(pos).attrs;
            tr.setNodeMarkup(tableStart + pos, null, setAttr(attrs, 'rowspan', attrs.rowspan + 1));
            col += attrs.colspan - 1;
        }
        else {
            const type = refRow == null ? tableNodeTypes(table.type.schema).cell : table.nodeAt(map$$1.map[index + refRow * map$$1.width]).type;
            cells.push(type.createAndFill());
        }
    }
    tr.insert(rowPos, tableNodeTypes(table.type.schema).row.create(null, cells));
    return tr;
};
/**
 * @hidden
 */
// Add a table row before the selection.
const addRowBefore$1 = (state, dispatch) => {
    if (!isInTable(state)) {
        return false;
    }
    if (dispatch) {
        const rect = selectedRect(state.selection);
        dispatch(addRow(state.tr, rect, rect.top));
    }
    return true;
};
/**
 * @hidden
 */
// Add a table row after the selection.
const addRowAfter$1 = (state, dispatch) => {
    if (!isInTable(state)) {
        return false;
    }
    if (dispatch) {
        const rect = selectedRect(state.selection);
        dispatch(addRow(state.tr, rect, rect.bottom));
    }
    return true;
};
/**
 * @hidden
 */
// Command function that removes the selected columns from a table.
const deleteColumn$1 = (state, dispatch) => {
    if (!isInTable(state)) {
        return false;
    }
    if (dispatch) {
        const rect = selectedRect(state.selection);
        // if only one column left - delete the whole table
        if (rect.left === 0 && rect.right === rect.map.width) {
            return deleteTable$1(state, dispatch);
        }
        const mainTable = findParentNodeClosestToPos(state.selection.$anchor, isTable);
        const tr = state.tr;
        removeTableGroupColumn(tr, rect, mainTable, isTableFoot, tr.doc);
        removeTableGroupColumn(tr, rect, mainTable, isTableBody, tr.doc);
        removeTableGroupColumn(tr, rect, mainTable, isTableHead, tr.doc);
        dispatch(tr);
    }
    return true;
};
const removeRow = (tr, { map: map$$1, table, tableStart }, row) => {
    let rowPos = 0;
    for (let i = 0; i < row; i++) {
        rowPos += table.child(i).nodeSize;
    }
    const nextRow = rowPos + table.child(row).nodeSize;
    const mapFrom = tr.mapping.maps.length;
    tr.delete(rowPos + tableStart, nextRow + tableStart);
    for (let col = 0, index = row * map$$1.width; col < map$$1.width; col++, index++) {
        const pos = map$$1.map[index];
        if (row > 0 && pos === map$$1.map[index - map$$1.width]) {
            // If this cell starts in the row above, simply reduce its rowspan
            const attrs = table.nodeAt(pos).attrs;
            tr.setNodeMarkup(tr.mapping.slice(mapFrom).map(pos + tableStart), null, setAttr(attrs, 'rowspan', attrs.rowspan - 1));
            col += attrs.colspan - 1;
        }
        else if (row < map$$1.width && pos === map$$1.map[index + map$$1.width]) {
            // Else, if it continues in the row below, it has to be moved down
            const cell = table.nodeAt(pos);
            const copy = cell.type.create(setAttr(cell.attrs, 'rowspan', cell.attrs.rowspan - 1), cell.content);
            const newPos = map$$1.positionAt(row + 1, col, table);
            tr.insert(tr.mapping.slice(mapFrom).map(tableStart + newPos), copy);
            col += cell.attrs.colspan - 1;
        }
    }
};
/**
 * @hidden
 */
// Remove the selected rows from a table.
const deleteRow$1 = (state, dispatch) => {
    if (!isInTable(state)) {
        return false;
    }
    if (dispatch) {
        const rect = selectedRect(state.selection);
        const tr = state.tr;
        if (rect.top === 0 && rect.bottom === rect.map.height) {
            const mainTable = findParentNodeClosestToPos(state.selection.$anchor, isTable);
            if (mainTable.node.childCount === 1) {
                deleteTable$1(state, dispatch);
            }
            else {
                deleteTableElement(n => n.type.name === rect.table.type.name)(state, dispatch);
            }
            return true;
        }
        for (let i = rect.bottom - 1;; i--) {
            removeRow(tr, rect, i);
            if (i === rect.top) {
                break;
            }
            rect.table = rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc;
            rect.map = TableMap.get(rect.table);
        }
        dispatch(tr);
    }
    return true;
};
/**
 * @hidden
 */
// Merge the selected cells into a single cell. Only available when
// the selected cells' outline forms a rectangle.
const mergeCells$1 = (state, dispatch) => {
    const sel = state.selection;
    if (!(sel instanceof CellSelection) || sel.$anchorCell.pos === sel.$headCell.pos) {
        return false;
    }
    const rect = selectedRect(state.selection);
    const { map: map$$1 } = rect;
    if (cellsOverlapRectangle(map$$1, rect)) {
        return false;
    }
    if (dispatch) {
        const tr = state.tr;
        const seen = {};
        let content = Fragment.empty;
        let mergedPos;
        let mergedCell;
        for (let row = rect.top; row < rect.bottom; row++) {
            for (let col = rect.left; col < rect.right; col++) {
                const cellPos = map$$1.map[row * map$$1.width + col], cell = rect.table.nodeAt(cellPos);
                if (seen[cellPos]) {
                    continue;
                }
                seen[cellPos] = true;
                if (mergedPos === null) {
                    mergedPos = cellPos;
                    mergedCell = cell;
                }
                else {
                    if (!isCellEmpty(cell)) {
                        content = content.append(cell.content);
                    }
                    const mapped = tr.mapping.map(cellPos + rect.tableStart);
                    tr.delete(mapped, mapped + cell.nodeSize);
                }
            }
        }
        tr.setNodeMarkup(mergedPos + rect.tableStart, null, setAttr(addColSpan(mergedCell.attrs, mergedCell.attrs.colspan, rect.right - rect.left - mergedCell.attrs.colspan), 'rowspan', rect.bottom - rect.top));
        if (content.size) {
            const end = mergedPos + 1 + mergedCell.content.size;
            const start = isCellEmpty(mergedCell) ? mergedPos + 1 : end;
            tr.replaceWith(start + rect.tableStart, end + rect.tableStart, content);
        }
        tr.setSelection(new CellSelection(tr.doc.resolve(mergedPos + rect.tableStart)));
        dispatch(tr);
    }
    return true;
};
/**
 * @hidden
 */
// Split a selected cell, whose rowpan or colspan is greater than one,
// into smaller cells. Use the first cell type for the new cells.
const splitCell$1 = (state, dispatch) => {
    const nodeTypes = tableNodeTypes(state.schema);
    return splitCellWithType(({ node }) => {
        return nodeTypes[node.type.spec.tableRole];
    })(state, dispatch);
};
const deleteTableElement = (selector) => (state, dispatch) => {
    const $pos = state.selection.$anchor;
    for (let d = $pos.depth; d > 0; d--) {
        const node = $pos.node(d);
        if (selector(node)) {
            if (dispatch) {
                const tr = state.tr;
                tr.delete($pos.before(d), $pos.after(d)).scrollIntoView();
                dispatch(tr);
            }
            return true;
        }
    }
    return false;
};
/**
 * @hidden
 */
// Deletes the table around the selection, if any.
const deleteTable$1 = deleteTableElement(isTable);

const alignRemove = (state, dispatch) => alignBlocks(alignRemoveRules)(state, dispatch);
const ɵ1$5 = (applyToWord) => expandToWordWrap(toggleInlineFormat, Object.assign({}, bold, { applyToWord })), ɵ2$4 = (options) => cleanFormatting(options), ɵ3$4 = attrs => expandToWordWrap(applyLink, { mark: 'link', attrs: attrs.value, applyToWord: attrs.applyToWord }), ɵ4$2 = attrs => expandToWordWrap(applyInlineStyle, { style: 'font-family', value: attrs.value, applyToWord: attrs.applyToWord }), ɵ5$2 = attrs => expandToWordWrap(applyInlineStyle, { style: 'font-size', value: attrs.value + 'px', applyToWord: attrs.applyToWord }), ɵ6$1 = attrs => expandToWordWrap(applyLink, { mark: 'link', attrs: attrs, applyToWord: attrs.applyToWord }), ɵ7$1 = text => insertText(text), ɵ8$1 = (applyToWord) => expandToWordWrap(toggleInlineFormat, Object.assign({}, italic, { applyToWord })), ɵ9$1 = (applyToWord) => expandToWordWrap(toggleInlineFormat, Object.assign({}, strikethrough, { applyToWord })), ɵ10 = (applyToWord) => expandToWordWrap(toggleInlineFormat, Object.assign({}, subscript, { applyToWord })), ɵ11 = (applyToWord) => expandToWordWrap(toggleInlineFormat, Object.assign({}, superscript, { applyToWord })), ɵ12 = (applyToWord) => expandToWordWrap(toggleInlineFormat, Object.assign({}, underline, { applyToWord })), ɵ13 = () => removeLink(link), ɵ14 = attrs => expandToWordWrap(applyInlineStyle, { style: 'color', value: attrs.value, applyToWord: attrs.applyToWord }), ɵ15 = attrs => expandToWordWrap(applyInlineStyle, { style: 'background-color', value: attrs.value, applyToWord: attrs.applyToWord }), ɵ16 = () => (state, dispatch) => selectAll(state, dispatch);
const inlineCommand = {
    bold: ɵ1$5,
    cleanFormatting: ɵ2$4,
    createLink: ɵ3$4,
    fontFamily: ɵ4$2,
    fontSize: ɵ5$2,
    insertFile: ɵ6$1,
    insertText: ɵ7$1,
    italic: ɵ8$1,
    strikethrough: ɵ9$1,
    subscript: ɵ10,
    superscript: ɵ11,
    underline: ɵ12,
    unlink: ɵ13,
    foreColor: ɵ14,
    backColor: ɵ15,
    selectAll: ɵ16
};
const ɵ17 = () => (state, dispatch) => isAligned(state, alignCenterRules) ? alignRemove(state, dispatch) : alignBlocks(alignCenterRules)(state, dispatch), ɵ18 = () => (state, dispatch) => isAligned(state, alignJustifyRules) ? alignRemove(state, dispatch) : alignBlocks(alignJustifyRules)(state, dispatch), ɵ19 = () => (state, dispatch) => isAligned(state, alignLeftRules) ? alignRemove(state, dispatch) : alignBlocks(alignLeftRules)(state, dispatch), ɵ20 = () => (state, dispatch) => isAligned(state, alignRightRules) ? alignRemove(state, dispatch) : alignBlocks(alignRightRules)(state, dispatch), ɵ21 = formatAttr => formatBlockElements(formatAttr.tag), ɵ22 = () => getHtml, ɵ23 = () => indent, ɵ24 = attrs => insertImage(attrs), ɵ25 = () => toggleOrderedList, ɵ26 = () => toggleUnorderedList, ɵ27 = () => outdent, ɵ28 = () => redo, ɵ29 = ({ content, parseOptions }) => setHtml(content, 'setHTML', parseOptions), ɵ30 = () => undo;
const blockCommand = {
    alignCenter: ɵ17,
    alignJustify: ɵ18,
    alignLeft: ɵ19,
    alignRight: ɵ20,
    format: ɵ21,
    getHTML: ɵ22,
    indent: ɵ23,
    insertImage: ɵ24,
    // think about changing the command name.
    insertOrderedList: ɵ25,
    // think about changing the command name.
    insertUnorderedList: ɵ26,
    outdent: ɵ27,
    redo: ɵ28,
    setHTML: ɵ29,
    undo: ɵ30
};
const ɵ31 = attr => insertTable(attr), ɵ32 = () => addColumnBefore$1, ɵ33 = () => addColumnAfter$1, ɵ34 = () => addRowBefore$1, ɵ35 = () => addRowAfter$1, ɵ36 = () => deleteRow$1, ɵ37 = () => deleteColumn$1, ɵ38 = () => mergeCells$1, ɵ39 = () => splitCell$1, ɵ40 = () => deleteTable$1;
const tableCommand = {
    insertTable: ɵ31,
    addColumnBefore: ɵ32,
    addColumnAfter: ɵ33,
    addRowBefore: ɵ34,
    addRowAfter: ɵ35,
    deleteRow: ɵ36,
    deleteColumn: ɵ37,
    mergeCells: ɵ38,
    splitCell: ɵ39,
    deleteTable: ɵ40
};
/**
 * @hidden
 */
const editorCommands = Object.assign({}, inlineCommand, blockCommand, tableCommand);

/**
 * @hidden
 */
const getToolbarState = (state, options) => ({
    alignCenter: {
        selected: isAligned(state, alignCenterRules),
        disabled: false
    },
    alignJustify: {
        selected: isAligned(state, alignJustifyRules),
        disabled: false
    },
    alignLeft: {
        selected: isAligned(state, alignLeftRules),
        disabled: false
    },
    alignRight: {
        selected: isAligned(state, alignRightRules),
        disabled: false
    },
    bold: {
        selected: hasMark(state, bold),
        disabled: false
    },
    cleanFormatting: {
        selected: false,
        disabled: !cleanFormatting()(state)
    },
    format: {
        selected: activeNode(state),
        disabled: false
    },
    indent: {
        selected: false,
        disabled: !(canIndentAsListItem(state, state.schema.nodes.list_item) || canBeIndented(state, indentRules))
    },
    insertOrderedList: {
        selected: hasNode(state, state.schema.nodes.ordered_list),
        disabled: false
    },
    insertUnorderedList: {
        selected: hasNode(state, state.schema.nodes.bullet_list),
        disabled: false
    },
    italic: {
        selected: hasMark(state, italic),
        disabled: false
    },
    unlink: {
        selected: false,
        disabled: !hasMark(state, link)
    },
    outdent: {
        selected: false,
        disabled: !(canOutdentAsListItem(state, outdentRules) || canBeIndented(state, outdentRules))
    },
    redo: {
        selected: false,
        disabled: !redo(state)
    },
    selectAll: {
        selected: false,
        disabled: false
    },
    strikethrough: {
        selected: hasMark(state, strikethrough),
        disabled: false
    },
    style: {
        selected: getActiveMarks(state, state.schema.marks.style),
        disabled: false
    },
    subscript: {
        selected: hasMark(state, subscript),
        disabled: false
    },
    superscript: {
        selected: hasMark(state, superscript),
        disabled: false
    },
    underline: {
        selected: hasMark(state, underline),
        disabled: false
    },
    undo: {
        selected: false,
        disabled: !undo(state)
    },
    //dialogs
    createLink: {
        selected: false,
        get disabled() {
            const empty = state.selection.empty;
            const applyToWord = options && options.applyToWord;
            if (applyToWord && empty) {
                const extendedState = expandSelection(state, () => { }, options).state;
                return extendedState.selection.empty;
            }
            return empty;
        }
    },
    insertFile: {
        selected: false,
        disabled: state.selection.empty
    },
    insertImage: {
        selected: false,
        disabled: false
    },
    viewSource: {
        selected: false,
        disabled: false
    },
    // print
    print: {
        selected: false,
        disabled: false
    },
    // table
    insertTable: {
        selected: false,
        disabled: false
    },
    addColumnBefore: {
        selected: false,
        disabled: !addColumnBefore(state)
    },
    addColumnAfter: {
        selected: false,
        disabled: !addColumnAfter(state)
    },
    addRowBefore: {
        selected: false,
        disabled: !addRowBefore(state)
    },
    addRowAfter: {
        selected: false,
        disabled: !addRowAfter(state)
    },
    deleteRow: {
        selected: false,
        disabled: !deleteRow(state)
    },
    deleteColumn: {
        selected: false,
        disabled: !deleteColumn(state)
    },
    mergeCells: {
        selected: false,
        disabled: !mergeCells(state)
    },
    splitCell: {
        selected: false,
        disabled: !splitCell(state)
    },
    deleteTable: {
        selected: false,
        disabled: !deleteTable(state)
    }
});
/**
 * @hidden
 */
const initialToolBarState = {
    //alignment
    alignCenter: { selected: false, disabled: false },
    alignJustify: { selected: false, disabled: false },
    alignLeft: { selected: false, disabled: false },
    alignRight: { selected: false, disabled: false },
    //marks
    bold: { selected: false, disabled: false },
    italic: { selected: false, disabled: false },
    underline: { selected: false, disabled: false },
    strikethrough: { selected: false, disabled: false },
    subscript: { selected: false, disabled: false },
    superscript: { selected: false, disabled: false },
    //tools
    format: { selected: { text: 'Format', tag: null }, disabled: false },
    style: { selected: { marks: [], hasNodesWithoutMarks: false }, disabled: false },
    cleanFormatting: { selected: false, disabled: true },
    //indent
    indent: { selected: false, disabled: false },
    outdent: { selected: false, disabled: false },
    //lists
    insertOrderedList: { selected: false, disabled: false },
    insertUnorderedList: { selected: false, disabled: false },
    //links
    unlink: { selected: false, disabled: true },
    //history
    redo: { selected: false, disabled: true },
    undo: { selected: false, disabled: true },
    // print
    print: { selected: false, disabled: false },
    //dialogs
    createLink: { selected: false, disabled: true },
    insertFile: { selected: false, disabled: true },
    insertImage: { selected: false, disabled: false },
    viewSource: { selected: false, disabled: false },
    //table
    insertTable: { selected: false, disabled: false },
    addColumnBefore: { selected: false, disabled: true },
    addColumnAfter: { selected: false, disabled: true },
    addRowBefore: { selected: false, disabled: true },
    addRowAfter: { selected: false, disabled: true },
    deleteRow: { selected: false, disabled: true },
    deleteColumn: { selected: false, disabled: true },
    mergeCells: { selected: false, disabled: true },
    splitCell: { selected: false, disabled: true },
    deleteTable: { selected: false, disabled: true },
    // select all
    selectAll: { selected: false, disabled: false }
};
/**
 * @hidden
 */
const disabledToolBarState = {
    //alignment
    alignCenter: { selected: false, disabled: true },
    alignJustify: { selected: false, disabled: true },
    alignLeft: { selected: false, disabled: true },
    alignRight: { selected: false, disabled: true },
    //marks
    bold: { selected: false, disabled: true },
    italic: { selected: false, disabled: true },
    underline: { selected: false, disabled: true },
    strikethrough: { selected: false, disabled: true },
    subscript: { selected: false, disabled: true },
    superscript: { selected: false, disabled: true },
    //tools
    format: { selected: { text: 'Format', tag: null }, disabled: true },
    style: { selected: { marks: [], hasNodesWithoutMarks: false }, disabled: true },
    cleanFormatting: { selected: false, disabled: true },
    //indent
    indent: { selected: false, disabled: true },
    outdent: { selected: false, disabled: true },
    //lists
    insertOrderedList: { selected: false, disabled: true },
    insertUnorderedList: { selected: false, disabled: true },
    //links
    unlink: { selected: false, disabled: true },
    //history
    redo: { selected: false, disabled: true },
    undo: { selected: false, disabled: true },
    // print
    print: { selected: false, disabled: true },
    //dialogs
    createLink: { selected: false, disabled: true },
    insertFile: { selected: false, disabled: true },
    insertImage: { selected: false, disabled: true },
    viewSource: { selected: false, disabled: true },
    //table
    insertTable: { selected: false, disabled: true },
    addColumnBefore: { selected: false, disabled: true },
    addColumnAfter: { selected: false, disabled: true },
    addRowBefore: { selected: false, disabled: true },
    addRowAfter: { selected: false, disabled: true },
    deleteRow: { selected: false, disabled: true },
    deleteColumn: { selected: false, disabled: true },
    mergeCells: { selected: false, disabled: true },
    splitCell: { selected: false, disabled: true },
    deleteTable: { selected: false, disabled: true },
    // select all
    selectAll: { selected: false, disabled: true }
};

/**
 * @hidden
 */
let EditorLocalizationService = class EditorLocalizationService extends LocalizationService {
    constructor(prefix, messageService, _rtl) {
        super(prefix, messageService, _rtl);
    }
};
EditorLocalizationService = __decorate([
    __param(0, Inject(L10N_PREFIX)),
    __param(1, Optional()),
    __param(2, Optional()), __param(2, Inject(RTL)),
    __metadata("design:paramtypes", [String, MessageService, Boolean])
], EditorLocalizationService);

/**
 * @hidden
 */
let SourceDialogComponent = class SourceDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
        this.data = '';
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.textarea.nativeElement.focus();
        });
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        this.editor.exec('setHTML', this.getData());
        this.dialog.close();
        this.editor.view.focus();
    }
    getData() {
        return this.textarea.nativeElement.value;
    }
    setData() {
        this.data = this.indent(this.editor.getSource());
    }
    textFor(key) {
        return this.localization.get(key);
    }
    indent(content) {
        return content
            .replace(/<\/(p|li|ul|ol|h[1-6]|table|tr|td|th)>/gi, '</$1>\n')
            .replace(/<(ul|ol)([^>]*)><li/gi, '<$1$2>\n<li')
            .replace(/<br \/>/gi, '<br />\n')
            .replace(/\n$/, '');
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], SourceDialogComponent.prototype, "editor", void 0);
__decorate([
    ViewChild('textarea', { static: true }),
    __metadata("design:type", ElementRef)
], SourceDialogComponent.prototype, "textarea", void 0);
SourceDialogComponent = __decorate([
    Component({
        styles: [
            `
            >>> .k-editor-textarea {
                height: 100%;
            }
        `
        ],
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor('viewSource') }}
        </kendo-dialog-titlebar>
        <textarea [value]="data" #textarea class="k-textarea k-editor-textarea"></textarea>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton (click)="onConfirmAction()" [primary]="true">{{ textFor('dialogUpdate') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], SourceDialogComponent);

/**
 * @hidden
 */
let ImageDialogComponent = class ImageDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
        this.src = new FormControl('', Validators.required);
        this.alt = new FormControl('');
        this.width = new FormControl('', Validators.min(1));
        this.height = new FormControl('', Validators.min(1));
        this.data = {
            alt: '',
            height: '',
            src: '',
            width: ''
        };
        this.imageData = new FormGroup({
            alt: this.alt,
            height: this.height,
            src: this.src,
            width: this.width
        });
    }
    ngOnInit() {
        this.srcInputId = `k-${guid()}`;
        this.altTextInputId = `k-${guid()}`;
        this.widthInputId = `k-${guid()}`;
        this.heightInputId = `k-${guid()}`;
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.srcInput.nativeElement.focus();
        });
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        if (this.src.value) {
            this.editor.exec('insertImage', this.getData());
            this.dialog.close();
            this.editor.view.focus();
        }
    }
    setData(state) {
        const node = getNodeFromSelection(state);
        if (node) {
            this.src.patchValue(node.attrs.src);
            this.alt.patchValue(node.attrs.alt);
            this.width.patchValue(node.attrs.width);
            this.height.patchValue(node.attrs.height);
        }
    }
    textFor(key) {
        return this.localization.get(key);
    }
    getData() {
        return {
            alt: this.alt.value,
            height: this.normalizeDimension(this.height.value),
            src: this.src.value,
            width: this.normalizeDimension(this.width.value)
        };
    }
    normalizeDimension(value) {
        return Number.isNaN(parseInt(value, 10)) || parseInt(value, 10) <= 0 ? '' : safeString(parseInt(value, 10));
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ImageDialogComponent.prototype, "editor", void 0);
__decorate([
    ViewChild('srcInput', { read: ElementRef, static: true }),
    __metadata("design:type", ElementRef)
], ImageDialogComponent.prototype, "srcInput", void 0);
ImageDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor('insertImage') }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-edit-form-container k-window-content">
                    <div class="k-edit-label">
                        <label [for]="srcInputId">{{ textFor('imageWebAddress') }}</label>
                    </div>
                    <div class="k-edit-field">
                        <input [id]="srcInputId" #srcInput [formControl]="src" type="text" class="k-textbox" />
                    </div>
                    <div class="k-edit-label">
                        <label [for]="altTextInputId">{{ textFor('imageAltText') }}</label>
                    </div>
                    <div class="k-edit-field">
                        <input [id]="altTextInputId" [formControl]="alt" type="text" class="k-textbox" />
                    </div>
                    <div class="k-edit-label">
                        <label [for]="widthInputId">{{ textFor('imageWidth') }}</label>
                    </div>
                    <div class="k-edit-field">
                        <input [id]="widthInputId" [formControl]="width" type="text" class="k-textbox" />
                    </div>
                    <div class="k-edit-label">
                        <label [for]="heightInputId">{{ textFor('imageHeight') }}</label>
                    </div>
                    <div class="k-edit-field">
                        <input [id]="heightInputId" [formControl]="height" type="text" class="k-textbox" />
                    </div>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton [disabled]="imageData.invalid"
                    (click)="onConfirmAction()" [primary]="true">{{ textFor('dialogInsert') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], ImageDialogComponent);

/**
 * @hidden
 */
let FileLinkDialogComponent = class FileLinkDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
        this.linkForm = new FormGroup({
            'href': new FormControl('', Validators.required),
            'text': new FormControl({ value: '', disabled: true }, Validators.required),
            'title': new FormControl('')
        });
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.hrefInput.nativeElement.focus();
        });
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        const linkData = this.getData();
        this.editor.exec(this.command, linkData);
        this.dialog.close();
        this.editor.view.focus();
    }
    get titleText() {
        return this.localization.get(this.command);
    }
    setData(state, options) {
        if (this.command === 'createLink') {
            this.linkForm.addControl('target', new FormControl());
        }
        const linkMark = getMark(state, state.schema.marks.link);
        if (linkMark) {
            // const linkMarkRange = getMarkRange(state.selection.$cursor, schema.marks.link);
            // const mark = parentNode.child(cursorNodeIndex).marks.find(m => m.type === markType);
            this.linkForm.reset({
                href: linkMark.attrs.href,
                title: linkMark.attrs.title,
                target: isPresent(linkMark.attrs.target),
                text: this.setLinkText(state)
            });
            return;
        }
        if (state.selection.empty) {
            const currentState = options.applyToWord ? expandSelection(state, () => { }, options).state : state;
            if (!currentState.selection.empty) {
                this.linkForm.patchValue({
                    'text': getSelectionText(currentState)
                });
            }
        }
        else {
            this.linkForm.patchValue({
                'text': getSelectionText(state)
            });
        }
    }
    textForWithPrefix(key) {
        const prefix = this.command === 'createLink' ? 'link' : 'file';
        return this.textFor(prefix + key);
    }
    textFor(key) {
        return this.localization.get(key);
    }
    setLinkText(state) {
        const selection = state.selection;
        if (selection.empty && selection.$cursor) {
            const cursor = selection.$cursor;
            const cursorNodeIndex = cursor.index();
            const parentNode = cursor.parent;
            return parentNode.child(cursorNodeIndex).text;
        }
        else {
            return getSelectionText(state);
        }
    }
    getData() {
        let linkData = this.linkForm.value;
        if (isPresent(this.linkForm.controls.target)) {
            linkData.target = linkData.target ? '_blank' : null;
        }
        return linkData;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FileLinkDialogComponent.prototype, "editor", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FileLinkDialogComponent.prototype, "command", void 0);
__decorate([
    ViewChild('hrefInput', { read: ElementRef, static: true }),
    __metadata("design:type", ElementRef)
], FileLinkDialogComponent.prototype, "hrefInput", void 0);
FileLinkDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ titleText }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-edit-form-container k-window-content">
                    <form novalidate [formGroup]="linkForm">
                        <div class="k-edit-label">
                            <label (click)="hrefInput.focus()">{{ textForWithPrefix('WebAddress') }}</label>
                        </div>
                        <div class="k-edit-field">
                            <input #hrefInput formControlName="href" type="text" class="k-textbox" />
                        </div>

                        <div class="k-edit-label">
                            <label (click)="textInput.focus()">{{ textForWithPrefix('Text') }}</label>
                        </div>
                        <div class="k-edit-field">
                            <input #textInput formControlName="text" type="text" class="k-textbox" />
                        </div>

                        <div class="k-edit-label">
                            <label (click)="titleInput.focus()">{{ textForWithPrefix('Title') }}</label>
                        </div>
                        <div class="k-edit-field">
                            <input #titleInput formControlName="title" type="text" class="k-textbox" />
                        </div>
                        <ng-container *ngIf="command === 'createLink'">
                            <div class="k-edit-label"></div>
                            <div class="k-edit-field">
                                <input type="checkbox" id="k-target-blank" class="k-checkbox" formControlName="target" />
                                <label class="k-checkbox-label" for="k-target-blank">{{ textForWithPrefix('OpenInNewWindow') }}</label>
                            </div>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton [disabled]="linkForm.invalid" (click)="onConfirmAction()" [primary]="true">
                {{ textFor('dialogInsert') }}
            </button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef, EditorLocalizationService])
], FileLinkDialogComponent);

/**
 * @hidden
 */
const defaultStyle = `
html, body {
    margin: 0;
    height: 100%;
    padding: 0;
}

html {
  min-height: 100%;
}

body {
  box-sizing: border-box;
  position: relative;
  padding: 8px;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

div.ProseMirror {
  position: relative;
  min-height: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
}

div.ProseMirror:focus {
  outline: none;
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror-hideselection *::selection { background: transparent; }
.ProseMirror-hideselection *::-moz-selection { background: transparent; }
.ProseMirror-hideselection { caret-color: transparent; }

.ProseMirror li {
  position: relative;
}

li.ProseMirror-selectednode {
  outline: none;
}

li.ProseMirror-selectednode:after {
  content: "";
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

.ProseMirror .k-placeholder::before {
    content: attr(data-placeholder);
    height: 0;
    color: #8a8a8a;
    float: left;
    opacity: 1;
    cursor: text;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* image resizing */
.k-editor-resize-handles-wrapper {
    position: absolute;
    visibility: hidden;
}

.k-editor-resize-handle {
    position: absolute;
    visibility: visible;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 100;
    width: 5px;
    height: 5px;
}

.k-editor-resize-handle.northwest {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    cursor: nw-resize;
}

.k-editor-resize-handle.north {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: n-resize;
}

.k-editor-resize-handle.northeast {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: ne-resize;
}

.k-editor-resize-handle.southwest {
    left: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
    cursor: sw-resize;
}

.k-editor-resize-handle.south {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    cursor: s-resize;
}

.k-editor-resize-handle.southeast {
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
    cursor: se-resize;
}

.k-editor-resize-handle.west {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    cursor: w-resize;
}

.k-editor-resize-handle.east {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    cursor: e-resize;
}
`;
/**
 * @hidden
 */
const tablesStyles = `
  .ProseMirror .tableWrapper {
    overflow-x: auto;
    margin: 1em 0;
  }

  .ProseMirror table {
    margin: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
  }

  .ProseMirror td, .ProseMirror th {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
  }

  .ProseMirror th {
    font-weight: bold;
    text-align: left;
  }

  .ProseMirror .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none;
  }

  .ProseMirror.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  /* Give selected cells a blue overlay */
  .ProseMirror .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }
`;
/**
 * @hidden
 */
const rtlStyles = 'body { direction: rtl }';

const ɵ0$6 = (arg) => `plugins must be a function, but received ${JSON.stringify(arg)}.`;
/* tslint:disable:variable-name */
/**
 * @hidden
 */
const EditorErrorMessages = {
    schemaType: 'Expected value of type Schema. See http://www.telerik.com/kendo-angular-ui/components/editor/schema/',
    setSchemaOnce: 'The Schema cannot be changed dynamically. See http://www.telerik.com/kendo-angular-ui/components/editor/schema/',
    pluginsCallbackType: ɵ0$6,
    setPluginsOnce: 'The plugins cannot be changed dynamically. See http://www.telerik.com/kendo-angular-ui/components/editor/plugins/',
    setPlaceHolderOnce: 'The placeholder cannot be changed dynamically. See http://www.telerik.com/kendo-angular-ui/components/editor/placeholder/',
    printTool: 'The Print tool and functionality are supported only in iframe mode. See https://www.telerik.com/kendo-angular-ui-develop/components/editor/api/EditorPrintButtonDirective/'
};

/**
 * @hidden
 */
let EditorToolsService = class EditorToolsService {
    /**
     * @hidden
     */
    constructor() {
        this.needsCheck = new Subject();
    }
};
EditorToolsService = __decorate([
    Injectable()
], EditorToolsService);

var EditorComponent_1;
const EMPTY_PARAGRAPH = '<p></p>';
const defaultPasteCleanupSettings = {
    convertMsLists: false,
    removeAttributes: [],
    removeHtmlComments: false,
    removeInvalidHTML: false,
    removeMsClasses: false,
    removeMsStyles: false,
    stripTags: ['']
};
const removeCommentsIf = conditionallyExecute(removeComments);
const removeInvalidHTMLIf = conditionallyExecute(sanitize);
const getPasteCleanupAttributes = (config) => {
    if (config.removeAttributes === 'all') {
        return { '*': removeAttribute };
    }
    const initial = removeEmptyEntries({
        style: config.removeMsStyles ? sanitizeStyleAttr : undefined,
        class: config.removeMsClasses ? sanitizeClassAttr : undefined
    });
    return config.removeAttributes.reduce((acc, curr) => (Object.assign({}, acc, { [curr]: removeAttribute })), initial);
};
/**
 * Represents the [Kendo UI Editor component for Angular]({% slug overview_editor %}).
 */
let EditorComponent = EditorComponent_1 = class EditorComponent {
    constructor(dialogService, localization, cdr, ngZone, element, toolsService) {
        this.dialogService = dialogService;
        this.localization = localization;
        this.cdr = cdr;
        this.ngZone = ngZone;
        this.element = element;
        this.toolsService = toolsService;
        /**
         * If set to `false`, the Editor will run in style non-encapsulated mode. This means
         * that the styles of the page will be persisted in the Editor and its content will be affected by them.
         * @default true
         */
        this.iframe = true;
        /**
         * If set to `true` or `ApplyToWordOptions` object, commands that apply emphasis or inline styles will be applied to the whole word the cursor is in.
         * @default false
         */
        this.applyToWord = false;
        /**
         * By design, the Editor emits `valueChange`, updates the model and the ToolBar state on each keystroke.
         * When you are interested in ignoring the new values for a given amout of time and take only the most recent one, you can use the `updateInterval` property.
         * A possible use case is to get the new values and to update the ToolBar state at a maximum rate per second in order to speed up your application.
         * The specified interval (in milliseconds) should be a positive number.
         * By default the `updateInterval` is set to 100 miliseconds. If set to zero the delay will be disabled entirely.
         */
        this.updateInterval = 100;
        /**
         * By default, whitespace is collapsed as per HTML's rules.
         * Set to `true` to preserve whitespace, but normalize newlines to spaces.
         * Set to `'full'` to preserve whitespace entirely. In this case the default ProseMirror behavior is to parse white space into nodes.
         *
         * @default false
         */
        this.preserveWhitespace = false;
        /**
         * Determines whether the Editor can be resized ([see example]({% slug styling_editor %}#toc-resizable-editor)).
         * @default false
         */
        this.resizable = false;
        /**
         * Fires each time the value of the Editor is changed upon user interaction&mdash;
         * for example, when the value is updated through typing in the content area or using some of the Editor tools ([see example]({% slug overview_editor %}#toc-events)).
         * When the value of the Editor is programmatically changed through its API (`ngModel`) or form binding (`formControl`),
         * the `valueChange` event is not triggered because it might cause a mix-up with the
         * built-in `valueChange` mechanisms of the `ngModel` or `formControl` bindings.
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires when the content area of the Editor is focused ([see example]({% slug overview_editor %}#toc-events)).
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires when the content area of the Editor is blurred ([see example]({% slug overview_editor %}#toc-events)).
         */
        this.onBlur = new EventEmitter();
        this.hostClass = true;
        /**
         * @hidden
         */
        this.stateChange = new BehaviorSubject(initialToolBarState);
        /**
         * @hidden
         */
        this.valueModified = new Subject();
        this._readonly = false;
        this._placeholder = '';
        this.afterViewInit = new Subject();
        this.contentAreaLoaded = new Subject();
        this.onChangeCallback = (_) => { }; // tslint:disable-line:no-empty
        this.onTouchedCallback = (_) => { }; // tslint:disable-line:no-empty
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        // https://stackoverflow.com/questions/56572483/chrome-is-synchronously-handling-iframe-loading-whereas-firefox-handles-it-asyn
        this.subs = zip(this.afterViewInit.asObservable(), this.contentAreaLoaded.asObservable()).subscribe(() => this.initialize());
    }
    /**
     * Sets the value of the Editor ([see example]({% slug overview_editor %}#toc-basic-usage)).
     */
    set value(value) {
        if (this._previousValue === value) {
            return;
        }
        this._value = value;
        this._previousValue = value;
        if (this._view) {
            const iframeContentWindowNotPresent = this.iframe && !this.container.element.nativeElement.contentWindow;
            if (iframeContentWindowNotPresent) {
                return;
            }
            this.exec('setHTML', {
                content: this._value || '',
                parseOptions: {
                    preserveWhitespace: this.preserveWhitespace
                }
            });
        }
    }
    get value() {
        let value = this._view ? this.getSource() : this._value;
        if (value === EMPTY_PARAGRAPH) {
            return this._value ? '' : this._value;
        }
        else {
            return value;
        }
    }
    /**
     * Sets the disabled state of the component.
     */
    set disabled(value) {
        this._disabled = value || false;
        if (this._view) {
            this._view.updateState(this._view.state);
        }
        if (this._disabled) {
            this.readonly = false;
        }
        if (this._disabled || this._readonly) {
            this.stateChange.next(disabledToolBarState);
        }
        else {
            this.stateChange.next(initialToolBarState);
        }
    }
    get disabled() {
        return this._disabled;
    }
    /**
     * Sets the read-only state of the component.
     */
    set readonly(value) {
        this._readonly = value || false;
        if (this._view) {
            // remove DOM selection
            let win;
            if (this.iframe) {
                win = this.container.element.nativeElement.contentWindow;
            }
            else {
                win = window;
            }
            const focusedNode = win.getSelection().focusNode;
            if (this._view.dom.contains(focusedNode)) {
                win.getSelection().removeAllRanges();
            }
            // remove ProseMirror selection
            const doc = this._view.state.doc;
            const tr = this._view.state.tr.setSelection(TextSelection.create(doc, 1));
            this._view.dispatch(tr);
        }
        if (this._readonly) {
            if (this.toolbar) {
                this.toolbar.tabindex = -1;
            }
            this.stateChange.next(disabledToolBarState);
        }
        else {
            if (this.toolbar) {
                this.toolbar.tabindex = 0;
            }
            this.stateChange.next(initialToolBarState);
        }
    }
    get readonly() {
        return this._readonly;
    }
    /**
     * Allows providing a custom schema. ([see example]({% slug schema_editor %}))
     */
    set schema(value) {
        if (isDevMode) {
            if (!(value instanceof Schema)) {
                throw new Error(EditorErrorMessages.schemaType);
            }
            if (this._view) {
                throw new Error(EditorErrorMessages.setSchemaOnce);
            }
        }
        this._schema = value;
    }
    get schema() {
        return this._schema;
    }
    /**
     * Defines a function which determines the plugins that will be used when initializing the Editor.
     * It exposes the default plugins collection as an argument, and returns
     * the plugins collection that will be used when creating the Editor component. ([see example]({% slug plugins_editor %}))
     *
     * ```ts-no-run
     *  pluginsCallback(defaultPlugins: Plugin[]): Plugin[] {
     *    const myPlugin = new Plugin({/custom plugin code/});
     *    return [...defaultPlugins, myPlugin];
     *  }
     * ```
     */
    set plugins(fn) {
        if (isDevMode) {
            if (typeof fn !== 'function') {
                throw new Error(EditorErrorMessages.pluginsCallbackType(fn));
            }
            if (this._view) {
                throw new Error(EditorErrorMessages.setPluginsOnce);
            }
        }
        this._plugins = fn;
    }
    get plugins() {
        return this._plugins;
    }
    /**
     * The hint, which is displayed when the component is empty.
     */
    set placeholder(value) {
        if (isDevMode && this._view) {
            throw new Error(EditorErrorMessages.setPlaceHolderOnce);
        }
        this._placeholder = value;
    }
    get placeholder() {
        return this._placeholder;
    }
    get resizableClass() {
        return !!this.resizable;
    }
    get isDisabled() {
        return this.disabled;
    }
    get isReadonly() {
        return this.readonly;
    }
    get isIE() {
        return this.iframe && detectIE();
    }
    get dir() {
        return this.direction;
    }
    get ariaDisabled() {
        return this.disabled;
    }
    get minWidth() {
        const resizableOptions = this.resizable;
        return resizableOptions.minWidth ? resizableOptions.minWidth + 'px' : undefined;
    }
    get maxWidth() {
        const resizableOptions = this.resizable;
        return resizableOptions.maxWidth ? resizableOptions.maxWidth + 'px' : undefined;
    }
    get minHeight() {
        const resizableOptions = this.resizable;
        return resizableOptions.minHeight ? resizableOptions.minHeight + 'px' : undefined;
    }
    get maxHeight() {
        const resizableOptions = this.resizable;
        return resizableOptions.maxHeight ? resizableOptions.maxHeight + 'px' : undefined;
    }
    get toolbar() {
        return this.defaultToolbarComponent || this.userToolBarComponent;
    }
    /**
     * Returns the ProseMirror [EditorView](https://prosemirror.net/docs/ref/#view.EditorView) object
     * that manages the DOM structure that represents an editable document.
     */
    get view() {
        return this._view;
    }
    /**
     * Returns the text which the current Editor selection contains ([see example]({% slug selection_editor %}#toc-retrieve-the-selected-text)).
     */
    get selectionText() {
        return this._view && this._view.state ? getSelectionText(this._view.state) : '';
    }
    ngOnInit() {
        this.subs.add(this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        }));
        this.subs.add(this.toolsService.needsCheck.subscribe(() => this.cdr.markForCheck()));
    }
    ngAfterViewInit() {
        this.afterViewInit.next();
        if (!this.iframe) {
            this.contentAreaLoaded.next();
        }
        if (this.resizable) {
            this.normalizeSize();
        }
    }
    ngOnChanges(changes) {
        if (changes.iframe && !changes.iframe.isFirstChange()) {
            this.ngZone.onStable.pipe(take(1)).subscribe(() => this.initialize());
        }
        if (changes.resizable && !changes.resizable.isFirstChange()) {
            this.normalizeSize();
        }
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    /**
     * @hidden
     */
    iframeOnLoad() {
        this.contentAreaLoaded.next();
    }
    /**
     * Executes a command on the currently selected text
     * ([more information and example]({% slug toolbartools_editor %}#toc-custom-tools)).
     *
     * @param {EditorCommand} commandName - The command that will be executed.
     * @param {any} attr - Optional parameters for the command. Apart from the following list,
     * the parameters do not expect specific attributes when you call them:
     * - `format` - Accepts an object with the `tag` property.
     * The supported tags are `p`, `blockquote`, and any of the `h1` to `h6` heading tags.
     * - `createLink` - Accepts an object with the `href`, `title`, and `target` properties. The `href` property is mandatory.
     * - `setHTML` - Accepts a `string` parameter.
     * - `insertTable` - Accepts an object with the `rows` and `cols` properties. The number values are zero based.
     *
     * @example
     * ```ts-no-run
     * // Toggles the bold styling.
     * editor.exec('bold');
     *
     * // Creates a bullet list.
     * editor.exec('insertUnorderedList');
     *
     * // Creates a link.
     * editor.exec('createLink', { href: 'www.progress.com', title: 'Progress', target: 'window' });
     *
     * // Inserts a file.
     * editor.exec('insertFile', { href: 'www.progress.com/resources/myfile.doc', title: 'My file', target: 'window' });
     *
     * // Inserts a image.
     * editor.exec('insertImage', { src: 'www.progress.com/resources/logo.jpg', title: 'Progress', target: 'window' });
     *
     * // Inserts a text at a given position. If no position is specified, the text will be inserted after the cursor.
     * editor.exec('insertText', { text: 'Hello World!', from: 0, to: 0 });
     *
     * // Changes the format of a text block.
     * editor.exec('format', { tag: 'h2' });
     *
     * // Changes the font size of the selected text.
     * editor.exec('fontSize', 24);
     *
     * // Changes the content of the Editor.
     * editor.exec('setHTML', '<p>HTML content</p>');
     *
     * // Creates and inserts a table with the specified number of rows and columns. Numbers are zero based.
     * this.editor.exec("insertTable", { rows: 3, cols: 5 });
     * ```
     */
    exec(commandName, attr) {
        // normalizes setHTML attributes
        if (commandName === 'setHTML' && typeof attr === 'string') {
            attr = {
                content: attr,
                parseOptions: {
                    preserveWhitespace: this.preserveWhitespace
                }
            };
        }
        else if (['fontFamily', 'fontSize', 'foreColor', 'backColor', 'createLink'].some(name => name === commandName)) {
            attr = {
                value: attr,
                applyToWord: this.applyToWord
            };
        }
        // Finds a command and applies the attributes.
        const command = editorCommands[commandName](attr);
        // Executes a ProseMirror command.
        command(this._view.state, this._view.dispatch, this._view);
        // See the `dispatchTransaction` comments.
        // this.stateChange.emit(updateToolBar(this.view));
    }
    /**
     * Opens a dialog.
     * @param {DialogCommand} dialogName - The name of the dialog that will open.
     *
     * The supported values are:
     * * `createLink`
     * * `viewSource`
     * * `insertFile`
     * * `insertImage`
     * * `tableWizard`
     *
     * @example
     * ```ts-no-run
     * // Opens a `createLink` dialog.
     * editor.openDialog('createLink');
     *
     * // Opens a `viewSource` dialog.
     * editor.openDialog('viewSource');
     * ```
     */
    openDialog(dialogName) {
        const editorDialogs = {
            createLink: {
                content: FileLinkDialogComponent
            },
            insertFile: {
                content: FileLinkDialogComponent
            },
            insertImage: {
                content: ImageDialogComponent
            },
            viewSource: {
                content: SourceDialogComponent,
                height: 400,
                width: 500
            }
            // tableWizard: {
            //     content: TableWizardDialogComponent
            // }
        };
        const dialog = Object.assign({ appendTo: this.dialogContainer }, editorDialogs[dialogName]);
        this.toolbar.toggle(false);
        const dialogContent = this.dialogService.open(dialog).content.instance;
        if (dialogName === 'createLink' || dialogName === 'insertFile') {
            dialogContent.command = dialogName;
        }
        dialogContent.editor = this;
        dialogContent.setData(this._view.state, { applyToWord: this.applyToWord });
    }
    /**
     * Manually focus the Editor.
     */
    focus() {
        this.focusChangedProgrammatically = true;
        this._view.focus();
        this.focusChangedProgrammatically = false;
    }
    /**
     * Manually blur the Editor.
     */
    blur() {
        this.focusChangedProgrammatically = true;
        this._view.dom.blur();
        this.focusChangedProgrammatically = false;
    }
    /**
     * @hidden
     */
    getSource() {
        return getHtml(this._view.state);
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
        if (this._styleObserver) {
            this._styleObserver.disconnect();
        }
    }
    /**
     * @hidden
     */
    writeValue(value) {
        // To avoid confusion, non-existent values are always undefined.
        this.value = value === null ? undefined : value;
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     * Used by the TextBoxContainer to determine if the component is empty.
     */
    isEmpty() {
        return false;
    }
    initialize() {
        if (!isDocumentAvailable()) {
            return;
        }
        const currentSchema = this.schema || schema;
        const that = this;
        const containerNativeElement = this.container.element.nativeElement;
        const contentNode = parseContent((this.value || '').trim(), currentSchema, { preserveWhitespace: this.preserveWhitespace });
        if (this.iframe) {
            const iframeDoc = containerNativeElement.contentDocument;
            const meta = iframeDoc.createElement('meta');
            meta.setAttribute('charset', 'utf-8');
            iframeDoc.head.appendChild(meta);
            [defaultStyle, tablesStyles, this.dir === 'rtl' ? rtlStyles : undefined].forEach(styles => {
                if (styles) {
                    const style = iframeDoc.createElement('style');
                    style.appendChild(iframeDoc.createTextNode(styles));
                    iframeDoc.head.appendChild(style);
                }
            });
            const element = iframeDoc.createElement('div');
            iframeDoc.body.appendChild(element);
        }
        else {
            const element = document.createElement('div');
            containerNativeElement.appendChild(element);
        }
        const defaultPlugins = [
            new Plugin({
                key: new PluginKey('editor-tabindex'),
                props: {
                    attributes: () => ({
                        // set tabindex when contenteditable is false, so that the content area can be selected
                        tabIndex: this.readonly ? '0' : ''
                    })
                }
            }),
            new Plugin({
                key: new PluginKey('toolbar-tools-update'),
                view: () => ({
                    update: editorView => {
                        if (!this.disabled) {
                            this.stateChange.next(this.readonly ? disabledToolBarState : getToolbarState(editorView.state, { applyToWord: this.applyToWord }));
                        }
                    }
                })
            }),
            new Plugin({
                key: new PluginKey('editor-filter-disabled-state'),
                filterTransaction: () => !this.disabled
            }),
            history(),
            keymap(buildListKeymap(currentSchema)),
            keymap(buildKeymap(currentSchema, { applyToWord: this.applyToWord })),
            keymap(baseKeymap),
            gapCursor(),
            imageResizing()
        ];
        if (this.placeholder) {
            defaultPlugins.push(placeholder(this.placeholder));
        }
        const state = EditorState.create({
            schema: currentSchema,
            doc: contentNode,
            plugins: isPresent(this.plugins) ? this.plugins(defaultPlugins) : defaultPlugins
        });
        if (this.iframe) {
            this.viewMountElement = containerNativeElement.contentDocument.querySelector('div');
        }
        else {
            this.viewMountElement = containerNativeElement.querySelector('div');
        }
        this.ngZone.runOutsideAngular(() => {
            this._view = new EditorView({ mount: this.viewMountElement }, {
                state,
                editable: () => !this.readonly,
                dispatchTransaction: function (tr) {
                    // `this` is bound to the view instance.
                    this.updateState(this.state.apply(tr));
                    // that.cdr.detectChanges();
                    // When the user utilizes keyboard shortcuts&mdash;for example, `Ctrl`+`b`&mdash;
                    // `tr.docChanged` is `true` and the toolbar is not updated.
                    // A possible future solution is to move the keymaps to the service.
                    // if (!tr.docChanged) {
                    //     that.stateChange.emit(updateToolBar(that.view));
                    // }
                    const value = that.value;
                    if (!hasSameMarkup(value, that._previousValue, this.state.schema)) {
                        that._previousValue = value;
                        that.ngZone.run(() => that.valueModified.next(value));
                    }
                },
                transformPastedHTML: (html) => {
                    const pasteCleanupSettings = Object.assign({}, defaultPasteCleanupSettings, this.pasteCleanupSettings);
                    const clean = pipe(removeCommentsIf(pasteCleanupSettings.removeHtmlComments), removeInvalidHTMLIf(pasteCleanupSettings.removeInvalidHTML))(html);
                    const attributes = getPasteCleanupAttributes(pasteCleanupSettings);
                    return pasteCleanup(clean, {
                        convertMsLists: pasteCleanupSettings.convertMsLists,
                        stripTags: pasteCleanupSettings.stripTags.join('|'),
                        attributes
                    });
                }
            });
        });
        if (this._view) {
            let containerElement;
            const contentAreaClasslist = this.element.nativeElement.querySelector('.k-editor-content').classList;
            if (this.iframe) {
                containerElement = this.container.element.nativeElement.contentDocument;
            }
            else {
                containerElement = this.container.element.nativeElement;
            }
            this.subs.add(fromEvent(containerElement, 'focusin')
                .subscribe(() => {
                if (this.readonly) {
                    contentAreaClasslist.add('k-state-focused');
                }
                if (!this.focusChangedProgrammatically || this.shouldEmitFocus) {
                    this.ngZone.run(() => this.onFocus.emit());
                    this.shouldEmitFocus = false;
                }
            }));
            this.subs.add(fromEvent(containerElement, 'focusout')
                .subscribe(() => {
                if (this.readonly) {
                    contentAreaClasslist.remove('k-state-focused');
                }
                if (!this.focusChangedProgrammatically) {
                    this.ngZone.run(() => this.onBlur.emit());
                }
            }));
        }
        this.subs.add(this.stateChange.subscribe(() => {
            if (this.userToolBarComponent) {
                this.userToolBarComponent.cdr.detectChanges();
            }
            else {
                this.cdr.detectChanges();
            }
        }));
        this.subs.add(merge(this.valueModified.pipe(filter(() => this.updateInterval > 0), auditTime(this.updateInterval)), this.valueModified.pipe(filter(() => this.updateInterval === 0))).subscribe((value) => {
            this.onChangeCallback(value);
            this.valueChange.emit(value);
            this.cdr.markForCheck();
        }));
        this.subs.add(fromEvent(this.viewMountElement, 'keyup')
            .pipe(map((e) => e.keyCode), filter((code) => code === 121), // F10
        map(() => this.userToolBarElement || this.defaultToolbar))
            .subscribe((toolbar) => toolbar.nativeElement.focus()));
        this.subs.add(fromEvent(this.viewMountElement, 'blur')
            .pipe(filter((event) => !this.viewMountElement.contains(event.relatedTarget)))
            .subscribe(() => this.onTouchedCallback()));
    }
    normalizeSize() {
        if (typeof this.resizable === 'object' && !this._styleObserver) {
            const element = this.element.nativeElement;
            this._styleObserver = new MutationObserver(() => {
                this.ngZone.runOutsideAngular(() => this.normalizeProperties(element));
            });
            this._styleObserver.observe(element, { attributeFilter: ['style'] });
        }
    }
    normalizeProperties(element) {
        const props = Object.keys(this.resizable);
        const pixelWidth = parseInt(element.style.width, 10);
        const pixelHeight = parseInt(element.style.height, 10);
        const resizable = this.resizable;
        props.forEach(prop => {
            const isMinProp = prop.startsWith('min');
            const isMaxProp = !isMinProp;
            const isWidthProp = prop.endsWith('Width');
            const isHeightProp = !isWidthProp;
            if (isMinProp && isWidthProp) {
                if (pixelWidth < resizable.minWidth) {
                    element.style.width = resizable.minWidth + 'px';
                }
            }
            else if (isMinProp && isHeightProp) {
                if (pixelHeight < resizable.minHeight) {
                    element.style.height = resizable.minHeight + 'px';
                }
            }
            else if (isMaxProp && isWidthProp) {
                if (pixelWidth > resizable.maxWidth) {
                    element.style.width = resizable.maxWidth + 'px';
                }
            }
            else {
                if (pixelHeight > resizable.maxHeight) {
                    element.style.height = resizable.maxHeight + 'px';
                }
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], EditorComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], EditorComponent.prototype, "disabled", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], EditorComponent.prototype, "readonly", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], EditorComponent.prototype, "iframe", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], EditorComponent.prototype, "applyToWord", void 0);
__decorate([
    Input(),
    __metadata("design:type", Schema),
    __metadata("design:paramtypes", [Schema])
], EditorComponent.prototype, "schema", null);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], EditorComponent.prototype, "plugins", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], EditorComponent.prototype, "placeholder", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], EditorComponent.prototype, "updateInterval", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], EditorComponent.prototype, "preserveWhitespace", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], EditorComponent.prototype, "pasteCleanupSettings", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], EditorComponent.prototype, "resizable", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], EditorComponent.prototype, "valueChange", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], EditorComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], EditorComponent.prototype, "onBlur", void 0);
__decorate([
    HostBinding('class.k-editor'),
    __metadata("design:type", Boolean)
], EditorComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-editor-resizable'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "resizableClass", null);
__decorate([
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-readonly'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "isReadonly", null);
__decorate([
    HostBinding('class.k-ie'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "isIE", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "dir", null);
__decorate([
    HostBinding('attr.ariaDisabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "ariaDisabled", null);
__decorate([
    HostBinding('style.minWidth'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "minWidth", null);
__decorate([
    HostBinding('style.maxWidth'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "maxWidth", null);
__decorate([
    HostBinding('style.minHeight'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "minHeight", null);
__decorate([
    HostBinding('style.maxHeight'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], EditorComponent.prototype, "maxHeight", null);
__decorate([
    ContentChild(ToolBarComponent, { static: false }),
    __metadata("design:type", ToolBarComponent)
], EditorComponent.prototype, "userToolBarComponent", void 0);
__decorate([
    ContentChild(ToolBarComponent, { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], EditorComponent.prototype, "userToolBarElement", void 0);
__decorate([
    ViewChild('dialogsContainer', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], EditorComponent.prototype, "dialogContainer", void 0);
__decorate([
    ViewChild('content', { read: ViewContainerRef, static: false }),
    __metadata("design:type", ViewContainerRef)
], EditorComponent.prototype, "container", void 0);
__decorate([
    ViewChild('defaultToolbar', { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], EditorComponent.prototype, "defaultToolbar", void 0);
__decorate([
    ViewChild('defaultToolbar', { read: ToolBarComponent, static: false }),
    __metadata("design:type", ToolBarComponent)
], EditorComponent.prototype, "defaultToolbarComponent", void 0);
EditorComponent = EditorComponent_1 = __decorate([
    Component({
        selector: 'kendo-editor',
        providers: [
            EditorLocalizationService,
            EditorToolsService,
            {
                provide: LocalizationService,
                useExisting: EditorLocalizationService
            },
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.editor'
            },
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => EditorComponent_1),
                multi: true
            },
            {
                provide: KendoInput,
                useExisting: forwardRef(() => EditorComponent_1)
            }
        ],
        /* tslint:disable:max-line-length */
        template: `
        <ng-container
            kendoEditorLocalizedMessages
            i18n-alignCenter="kendo.editor.alignCenter|The title of the tool that aligns text in the center."
            alignCenter="Center text"
            i18n-alignJustify="kendo.editor.alignJustify|The title of the tool that justifies text both left and right."
            alignJustify="Justify"
            i18n-alignLeft="kendo.editor.alignLeft|The title of the tool that aligns text on the left."
            alignLeft="Align text left"
            i18n-alignRight="kendo.editor.alignRight|The title of the tool that aligns text on the right."
            alignRight="Align text right"
            i18n-backColor="kendo.editor.backColor|The title of the tool that changes the text background color."
            backColor="Background color"
            i18n-bold="kendo.editor.bold|The title of the tool that makes text bold."
            bold="Bold"
            i18n-cleanFormatting="kendo.editor.cleanFormatting|The title of the Clean Formatting tool."
            cleanFormatting="Clean formatting"
            i18n-createLink="kendo.editor.createLink|The title of the tool that creates hyperlinks."
            createLink="Insert link"
            i18n-dialogApply="kendo.editor.dialogApply|The label of the **Apply** button in all editor dialogs."
            dialogApply="Apply"
            i18n-dialogCancel="kendo.editor.dialogCancel|The label of the **Cancel** button in all editor dialogs."
            dialogCancel="Cancel"
            i18n-dialogInsert="kendo.editor.dialogInsert|The label of the **Insert** button in all editor dialogs."
            dialogInsert="Insert"
            i18n-dialogUpdate="kendo.editor.dialogUpdate|The label of the **Update** button in all editor dialogs."
            dialogUpdate="Update"
            i18n-fileText="kendo.editor.fileText|The caption for the file text in the insertFile dialog."
            fileText="Text"
            i18n-fileTitle="kendo.editor.fileTitle|The caption for the file Title in the insertFile dialog."
            fileTitle="Title"
            i18n-fileWebAddress="kendo.editor.fileWebAddress|The caption for the file URL in the insertFile dialog."
            fileWebAddress="Web address"
            i18n-fontFamily="kendo.editor.fontFamily|The title of the tool that changes the text font."
            fontFamily="Select font family"
            i18n-fontSize="kendo.editor.fontSize|The title of the tool that changes the text size."
            fontSize="Select font size"
            i18n-foreColor="kendo.editor.foreColor|The title of the tool that changes the text color."
            foreColor="Color"
            i18n-format="kendo.editor.format|The title of the tool that lets users choose block formats."
            format="Format"
            i18n-imageAltText="kendo.editor.imageAltText|The caption for the image alternate text in the insertImage dialog."
            imageAltText="Alternate text"
            i18n-imageHeight="kendo.editor.imageHeight|The caption for the image height in the insertImage dialog."
            imageHeight="Height (px)"
            i18n-imageWebAddress="kendo.editor.imageWebAddress|The caption for the image URL in the insertImage dialog."
            imageWebAddress="Web address"
            i18n-imageWidth="kendo.editor.imageWidth|The caption for the image width in the insertImage dialog."
            imageWidth="Width (px)"
            i18n-indent="kendo.editor.indent|The title of the tool that indents the content."
            indent="Indent"
            i18n-insertFile="kendo.editor.insertFile|The title of the tool that inserts links to files."
            insertFile="Insert file"
            i18n-insertImage="kendo.editor.insertImage|The title of the tool that inserts images."
            insertImage="Insert image"
            i18n-insertOrderedList="kendo.editor.insertOrderedList|The title of the tool that inserts an ordered list."
            insertOrderedList="Insert ordered list"
            i18n-insertUnorderedList="kendo.editor.insertUnorderedList|The title of the tool that inserts an unordered list."
            insertUnorderedList="Insert unordered list"
            i18n-italic="kendo.editor.italic|The title of the tool that makes text italicized."
            italic="Italic"
            i18n-linkOpenInNewWindow="kendo.editor.linkOpenInNewWindow|The caption for the checkbox for opening the link in a new window in the createLink dialog."
            linkOpenInNewWindow="Open link in new window"
            i18n-linkText="kendo.editor.linkText|The caption for the link text in the createLink dialog."
            linkText="Text"
            i18n-linkTitle="kendo.editor.linkTitle|The caption for the link title in the createLink dialog."
            linkTitle="Title"
            i18n-linkWebAddress="kendo.editor.linkWebAddress|The caption for the URL in the createLink dialog."
            linkWebAddress="Web address"
            i18n-outdent="kendo.editor.outdent|The title of the tool that outdents the content."
            outdent="Outdent"
            i18n-print="kendo.editor.print|The title of the print tool."
            print="Print"
            i18n-redo="kendo.editor.redo|The title of the tool that undos the last action."
            redo="Redo"
            i18n-selectAll="kendo.editor.selectAll|The title of the tool that selects all content."
            selectAll="Select All"
            i18n-strikethrough="kendo.editor.strikethrough|The title of the tool that strikes through text."
            strikethrough="Strikethrough"
            i18n-subscript="kendo.editor.subscript|The title of the tool that makes text subscript."
            subscript="Subscript"
            i18n-superscript="kendo.editor.superscript|The title of the tool that makes text superscript."
            superscript="Superscript"
            i18n-underline="kendo.editor.underline|The title of the tool that underlines text."
            underline="Underline"
            i18n-unlink="kendo.editor.unlink|The title of the tool that removes hyperlinks."
            unlink="Remove Link"
            i18n-undo="kendo.editor.undo|The title of the tool that undos the last action."
            undo="Undo"
            i18n-viewSource="kendo.editor.viewSource|The title of the tool that shows the editor value as HTML."
            viewSource="View source"
            i18n-insertTable="kendo.editor.insertTable|The title of the tool that inserts table."
            insertTable="Insert Table"
            i18n-addColumnBefore="kendo.editor.addColumnBefore|The title of the tool that adds new column before currently selected column."
            addColumnBefore="Add column before"
            i18n-addColumnAfter="kendo.editor.addColumnAfter|The title of the tool that adds new column after currently selected column."
            addColumnAfter="Add column after"
            i18n-addRowBefore="kendo.editor.addRowBefore|The title of the tool that adds new row before currently selected row."
            addRowBefore="Add row before"
            i18n-addRowAfter="kendo.editor.addRowAfter|The title of the tool that adds new row after currently selected row."
            addRowAfter="Add row after"
            i18n-deleteColumn="kendo.editor.deleteColumn|The title of the tool that deletes a table column."
            deleteColumn="Delete column"
            i18n-deleteRow="kendo.editor.deleteRow|The title of the tool that deletes a table row."
            deleteRow="Delete row"
            i18n-deleteTable="kendo.editor.deleteTable|The title of the tool that deletes a table."
            deleteTable="Delete table"
        >
        </ng-container>

        <ng-content select="kendo-toolbar"></ng-content>
        <kendo-toolbar [overflow]="true" [tabindex]="readonly ? -1 : 0" *ngIf="!userToolBarElement" #defaultToolbar>
            <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
        </kendo-toolbar>

        <div *ngIf="!iframe" #content [attr.dir]="direction" class="k-editor-content"></div>

        <div class="k-editor-content" *ngIf="iframe">
            <iframe #content frameborder="0" class="k-iframe" (load)="iframeOnLoad()"></iframe>
        </div>

        <ng-container #dialogsContainer></ng-container>
    `,
        styles: [
            `
            >>> .k-editor-content > .ProseMirror {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                outline: none;
                overflow: auto;
            }

            .k-iframe {
                width: 100%;
                height: 100%;
                display: block;
            }
        `
        ]
    }),
    __metadata("design:paramtypes", [DialogService,
        LocalizationService,
        ChangeDetectorRef,
        NgZone,
        ElementRef,
        EditorToolsService])
], EditorComponent);

/**
 * @hidden
 */
let FormatDropDownListComponent = class FormatDropDownListComponent {
    /**
     * @hidden
     */
    constructor() {
        this.valueChange = new EventEmitter();
    }
    onValueChange(tag) {
        this.valueChange.emit(tag);
    }
    focus() {
        this.dropDownList.focus();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], FormatDropDownListComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FormatDropDownListComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FormatDropDownListComponent.prototype, "defaultItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], FormatDropDownListComponent.prototype, "itemDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FormatDropDownListComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FormatDropDownListComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FormatDropDownListComponent.prototype, "tabindex", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FormatDropDownListComponent.prototype, "valueChange", void 0);
__decorate([
    ViewChild('element', { static: true }),
    __metadata("design:type", ElementRef)
], FormatDropDownListComponent.prototype, "element", void 0);
__decorate([
    ViewChild('element', { read: DropDownListComponent, static: true }),
    __metadata("design:type", DropDownListComponent)
], FormatDropDownListComponent.prototype, "dropDownList", void 0);
FormatDropDownListComponent = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        selector: 'kendo-editor-format-dropdownlist',
        template: `
        <kendo-dropdownlist
            #element
            kendoEditorDropDownTool
            [defaultItem]="defaultItem"
            [textField]="'text'"
            [valueField]="'tag'"
            [data]="data"
            [(value)]="value"
            [valuePrimitive]="true"
            [itemDisabled]="itemDisabled"
            [attr.title]="title"
            [disabled]="disabled"
            [tabindex]="tabindex"
            (valueChange)="onValueChange($event)"
        >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <ng-container [ngSwitch]="dataItem.tag">
                    <span *ngSwitchCase="'h1'" style="display: block; font-size: 2em; margin-left: 0; font-weight: bold;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'h2'" style="display: block; font-size: 1.5em; margin-left: 0; font-weight: bold;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'h3'" style="display: block; font-size: 1.17em; margin-left: 0; font-weight: bold;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'h4'" style="display: block; font-size: 1em; margin-left: 0; font-weight: bold;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'h5'" style="display: block; font-size: .83em; margin-left: 0; font-weight: bold;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'h6'" style="display: block; font-size: .67em; margin-left: 0; font-weight: bold;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'p'" style="display: block; margin-left: 0;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchCase="'blockquote'" style="display: block; margin-left: 0;">
                        {{ dataItem.text }}
                    </span>

                    <span *ngSwitchDefault>{{ dataItem.text }}</span>
                </ng-container>
            </ng-template>
        </kendo-dropdownlist>
    `
    })
], FormatDropDownListComponent);

/**
 * @hidden
 */
let FormatDialogComponent = class FormatDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
        this.data = [];
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.formatDropDownList.dropDownList.focus();
        });
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        if (this.value) {
            this.editor.exec('format', { tag: this.value });
        }
        this.dialog.close();
    }
    setData(args) {
        this.editor = args.editor;
        this.data = args.data;
        this.defaultItem = args.defaultItem;
        this.value = args.value;
        this.itemDisabled = args.itemDisabled;
    }
    textFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", EditorComponent)
], FormatDialogComponent.prototype, "editor", void 0);
__decorate([
    ViewChild('formatDropDownList', { read: FormatDropDownListComponent, static: true }),
    __metadata("design:type", FormatDropDownListComponent)
], FormatDialogComponent.prototype, "formatDropDownList", void 0);
FormatDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor('format') }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-edit-form-container k-window-content" style="text-align: center;">
                    <kendo-editor-format-dropdownlist
                        #formatDropDownList
                        [defaultItem]="defaultItem"
                        [data]="data"
                        [(value)]="value"
                        [itemDisabled]="itemDisabled"
                    >
                    </kendo-editor-format-dropdownlist>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton
                    (click)="onConfirmAction()" [primary]="true">{{ textFor('dialogApply') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], FormatDialogComponent);

/**
 * @hidden
 */
let ColorPickerDialogComponent = class ColorPickerDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.colorPicker.focus();
        });
    }
    handleActiveColorClick(event) {
        event.preventOpen();
        this.value = event.color;
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        if (this.value) {
            this.editor.exec(this.editorCommand, this.value);
        }
        this.dialog.close();
    }
    setData(args) {
        this.editor = args.editor;
        this.value = args.value;
        this.editorCommand = args.editorCommand;
        this.paletteSettings = args.paletteSettings;
        this.icon = args.icon;
        this.view = args.view;
    }
    textFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", EditorComponent)
], ColorPickerDialogComponent.prototype, "editor", void 0);
__decorate([
    ViewChild('colorpicker', { read: ColorPickerComponent, static: true }),
    __metadata("design:type", ColorPickerComponent)
], ColorPickerDialogComponent.prototype, "colorPicker", void 0);
ColorPickerDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor(editorCommand) }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-edit-form-container k-window-content" style="text-align: center;">
                    <kendo-colorpicker
                        #colorpicker
                        [view]="view"
                        [format]="'hex'"
                        [attr.title]="title"
                        [icon]="icon"
                        [(value)]="value"
                        [paletteSettings]="paletteSettings"
                        (activeColorClick)="handleActiveColorClick($event)"
                    >
                    </kendo-colorpicker>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton
                    (click)="onConfirmAction()" [primary]="true">{{ textFor('dialogApply') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], ColorPickerDialogComponent);

/**
 * @hidden
 */
let FontFamilyDropDownListComponent = class FontFamilyDropDownListComponent {
    /**
     * @hidden
     */
    constructor() {
        this.valueChange = new EventEmitter();
    }
    onValueChange(tag) {
        this.valueChange.emit(tag);
    }
    focus() {
        this.dropDownList.focus();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], FontFamilyDropDownListComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FontFamilyDropDownListComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FontFamilyDropDownListComponent.prototype, "defaultItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], FontFamilyDropDownListComponent.prototype, "itemDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FontFamilyDropDownListComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FontFamilyDropDownListComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FontFamilyDropDownListComponent.prototype, "tabindex", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FontFamilyDropDownListComponent.prototype, "valueChange", void 0);
__decorate([
    ViewChild('element', { static: true }),
    __metadata("design:type", ElementRef)
], FontFamilyDropDownListComponent.prototype, "element", void 0);
__decorate([
    ViewChild('element', { read: DropDownListComponent, static: true }),
    __metadata("design:type", DropDownListComponent)
], FontFamilyDropDownListComponent.prototype, "dropDownList", void 0);
FontFamilyDropDownListComponent = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        selector: 'kendo-editor-fontfamily-dropdownlist',
        template: `
        <kendo-dropdownlist
            #element
            kendoEditorDropDownTool
            [defaultItem]="defaultItem"
            [textField]="'text'"
            [valueField]="'fontName'"
            [data]="data"
            [(value)]="value"
            [valuePrimitive]="true"
            [itemDisabled]="itemDisabled"
            [attr.title]="title"
            [disabled]="disabled"
            [tabindex]="tabindex"
            (valueChange)="onValueChange($event)"
        >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span [ngStyle]="{ 'font-family': dataItem.fontName }">
                    {{ dataItem.text }}
                </span>
            </ng-template>
        </kendo-dropdownlist>
    `
    })
], FontFamilyDropDownListComponent);

/**
 * @hidden
 */
let FontFamilyDialogComponent = class FontFamilyDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
        this.data = [];
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.fontFamilyDropDownList.dropDownList.focus();
        });
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        if (this.value) {
            this.editor.exec('fontFamily', this.value);
        }
        this.dialog.close();
    }
    setData(args) {
        this.editor = args.editor;
        this.data = args.data;
        this.defaultItem = args.defaultItem;
        this.value = args.value;
        this.itemDisabled = args.itemDisabled;
    }
    textFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", EditorComponent)
], FontFamilyDialogComponent.prototype, "editor", void 0);
__decorate([
    ViewChild('fontFamilyDropDownList', { read: FontFamilyDropDownListComponent, static: true }),
    __metadata("design:type", FontFamilyDropDownListComponent)
], FontFamilyDialogComponent.prototype, "fontFamilyDropDownList", void 0);
FontFamilyDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor('fontFamily') }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-edit-form-container k-window-content" style="text-align: center;">
                    <kendo-editor-fontfamily-dropdownlist
                        #fontFamilyDropDownList
                        [defaultItem]="defaultItem"
                        [data]="data"
                        [(value)]="value"
                        [itemDisabled]="itemDisabled"
                    >
                    </kendo-editor-fontfamily-dropdownlist>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton
                    (click)="onConfirmAction()" [primary]="true">{{ textFor('dialogApply') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], FontFamilyDialogComponent);

/**
 * @hidden
 */
let InsertTableDialogComponent = class InsertTableDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
    }
    onCancelAction() {
        this.dialog.close();
        this.editor.view.focus();
    }
    onCellClick(args) {
        this.dialog.close();
        this.editor.exec("insertTable", args);
        this.editor.view.focus();
    }
    setData(args) {
        this.editor = args.editor;
    }
    textFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", EditorComponent)
], InsertTableDialogComponent.prototype, "editor", void 0);
InsertTableDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor('insertTable') }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-ct-popup k-window-content" style="text-align: center;">
                    <kendo-popup-table-grid (cellClick)="onCellClick($event)"></kendo-popup-table-grid>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], InsertTableDialogComponent);

var EditorFormatComponent_1;
/**
 * A component which configures an existing `DropDownListComponent` as an Editor tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The component associates a `kendo-dropdownlist` with an Editor command that changes the format of a content block and
 * automatically defines the options of the drop-down list and sets its values.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
 * ```
 */
let EditorFormatComponent = EditorFormatComponent_1 = class EditorFormatComponent extends ToolBarToolComponent {
    constructor(editor, dialogService, localization, toolsService) {
        super();
        this.editor = editor;
        this.dialogService = dialogService;
        this.localization = localization;
        this.toolsService = toolsService;
        this.disabled = false;
        this.tabindex = -1;
        /**
         * Fires when the user updates the value of the drop-down list.
         */
        this.valueChange = new EventEmitter();
        this._data = [
            { text: 'Paragraph', tag: 'p' },
            { text: 'Quotation', tag: 'blockquote' },
            { text: 'Heading 1', tag: 'h1' },
            { text: 'Heading 2', tag: 'h2' },
            { text: 'Heading 3', tag: 'h3' },
            { text: 'Heading 4', tag: 'h4' },
            { text: 'Heading 5', tag: 'h5' },
            { text: 'Heading 6', tag: 'h6' }
        ];
    }
    /**
     * Overrides the default format items list.
     */
    set data(formatItems) {
        this._data = formatItems || this._data;
    }
    get data() {
        return this._data;
    }
    ngOnInit() {
        this.itemDisabled = (itemArgs) => {
            if (!this.overflows && this.formatDropDownList && !this.formatDropDownList.dropDownList.isOpen) {
                return true; //disable all items in order to prevent navigation when DDL is closed
            }
            else {
                return itemArgs.dataItem.tag === null;
            }
        };
        setTimeout(() => {
            this.defaultItem = { text: this.title, tag: null };
            this.toolsService.needsCheck.next();
        });
        this.subs = this.editor.stateChange.subscribe(({ format }) => {
            const index = this.data.findIndex(item => item.tag === format.selected.tag);
            this.value = index !== -1 ? format.selected.tag : null;
            this.disabled = format.disabled;
        });
    }
    /**
     * @hidden
     */
    onValueChange(ev) {
        if (isPresent(ev)) {
            this.editor.exec('format', { tag: ev });
            this.editor.view.focus();
            this.valueChange.emit(this.data.find(d => d.tag === ev));
        }
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    get outerWidth() {
        const element = this.formatDropDownList.element;
        if (element) {
            return outerWidth(element.nativeElement);
        }
    }
    get title() {
        return this.localization.get('format');
    }
    /**
     * @hidden
     */
    openDialog() {
        const dialogSettings = {
            appendTo: this.editor.dialogContainer,
            content: FormatDialogComponent
        };
        this.editor.toolbar.toggle(false);
        const dialogContent = this.dialogService.open(dialogSettings).content.instance;
        dialogContent.setData({
            editor: this.editor,
            data: this.data,
            defaultItem: this.defaultItem,
            value: this.value,
            itemDisabled: this.itemDisabled
        });
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus() {
        this.tabindex = 0;
        if (this.overflows) {
            this.formatButton.nativeElement.focus();
        }
        else {
            this.formatDropDownList.focus();
        }
    }
    /**
     * @hidden
     */
    handleKey() {
        this.tabindex = -1;
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], EditorFormatComponent.prototype, "data", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], EditorFormatComponent.prototype, "valueChange", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorFormatComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorFormatComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('formatDropDownList', { static: false }),
    __metadata("design:type", FormatDropDownListComponent)
], EditorFormatComponent.prototype, "formatDropDownList", void 0);
__decorate([
    ViewChild('formatButton', { static: false }),
    __metadata("design:type", ElementRef)
], EditorFormatComponent.prototype, "formatButton", void 0);
EditorFormatComponent = EditorFormatComponent_1 = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => EditorFormatComponent_1) }],
        selector: 'kendo-toolbar-dropdownlist[kendoEditorFormat]',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-editor-format-dropdownlist
                #formatDropDownList
                [defaultItem]="defaultItem"
                [data]="data"
                [(value)]="value"
                [itemDisabled]="itemDisabled"
                [title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (valueChange)="onValueChange($event)"
            >
            </kendo-editor-format-dropdownlist>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                #formatButton
                [tabindex]="tabindex"
                type="button"
                kendoButton
                class="k-overflow-button"
                [icon]="'apply-format'"
                [attr.title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (click)="openDialog()"
            >
                {{ title }}
            </button>
        </ng-template>
    `
    }),
    __param(0, Host()),
    __metadata("design:paramtypes", [EditorComponent,
        DialogService,
        EditorLocalizationService,
        EditorToolsService])
], EditorFormatComponent);

/**
 * @hidden
 */
let FontSizeDropDownListComponent = class FontSizeDropDownListComponent {
    /**
     * @hidden
     */
    constructor() {
        this.valueChange = new EventEmitter();
    }
    onValueChange(size) {
        this.valueChange.emit(size);
    }
    focus() {
        this.dropDownList.focus();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], FontSizeDropDownListComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FontSizeDropDownListComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FontSizeDropDownListComponent.prototype, "defaultItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], FontSizeDropDownListComponent.prototype, "itemDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FontSizeDropDownListComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FontSizeDropDownListComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FontSizeDropDownListComponent.prototype, "tabindex", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FontSizeDropDownListComponent.prototype, "valueChange", void 0);
__decorate([
    ViewChild('element', { static: true }),
    __metadata("design:type", ElementRef)
], FontSizeDropDownListComponent.prototype, "element", void 0);
__decorate([
    ViewChild('element', { read: DropDownListComponent, static: true }),
    __metadata("design:type", DropDownListComponent)
], FontSizeDropDownListComponent.prototype, "dropDownList", void 0);
FontSizeDropDownListComponent = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        selector: 'kendo-editor-fontsize-dropdownlist',
        template: `
        <kendo-dropdownlist
            #element
            kendoEditorDropDownTool
            [defaultItem]="defaultItem"
            [textField]="'text'"
            [valueField]="'size'"
            [data]="data"
            [(value)]="value"
            [valuePrimitive]="true"
            [itemDisabled]="itemDisabled"
            [attr.title]="title"
            [disabled]="disabled"
            [tabindex]="tabindex"
            (valueChange)="onValueChange($event)"
        >
        </kendo-dropdownlist>
    `
    })
], FontSizeDropDownListComponent);

/**
 * @hidden
 */
let FontSizeDialogComponent = class FontSizeDialogComponent extends DialogContentBase {
    constructor(dialog, localization) {
        super(dialog);
        this.dialog = dialog;
        this.localization = localization;
        this.data = [];
    }
    ngAfterViewInit() {
        Promise.resolve(null).then(() => {
            this.fontSizeDropDownList.dropDownList.focus();
        });
    }
    onCancelAction() {
        this.dialog.close();
    }
    onConfirmAction() {
        if (this.value) {
            this.editor.exec('fontSize', this.value);
        }
        this.dialog.close();
    }
    setData(args) {
        this.editor = args.editor;
        this.data = args.data;
        this.defaultItem = args.defaultItem;
        this.value = args.value;
        this.itemDisabled = args.itemDisabled;
    }
    textFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", EditorComponent)
], FontSizeDialogComponent.prototype, "editor", void 0);
__decorate([
    ViewChild('fontSizeDropDownList', { read: FontSizeDropDownListComponent, static: true }),
    __metadata("design:type", FontSizeDropDownListComponent)
], FontSizeDialogComponent.prototype, "fontSizeDropDownList", void 0);
FontSizeDialogComponent = __decorate([
    Component({
        template: `
        <kendo-dialog-titlebar (close)="onCancelAction()">
            {{ textFor('fontSize') }}
        </kendo-dialog-titlebar>
        <div class="k-editor-dialog">
            <div class="k-editor-dialog k-popup-edit-form k-window-content k-content">
                <div class="k-edit-form-container k-window-content" style="text-align: center;">
                    <kendo-editor-fontsize-dropdownlist
                        #fontSizeDropDownList
                        [defaultItem]="defaultItem"
                        [data]="data"
                        [(value)]="value"
                        [itemDisabled]="itemDisabled"
                    >
                    </kendo-editor-fontsize-dropdownlist>
                </div>
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="onCancelAction()">{{ textFor('dialogCancel') }}</button>
            <button kendoButton
                    (click)="onConfirmAction()" [primary]="true">{{ textFor('dialogApply') }}</button>
        </kendo-dialog-actions>
    `
    }),
    __metadata("design:paramtypes", [DialogRef,
        EditorLocalizationService])
], FontSizeDialogComponent);

var EditorFontSizeComponent_1;
/**
 * A component which configures an existing `DropDownListComponent` as an Editor tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The component associates a `kendo-dropdownlist` with an Editor command that changes the font size of a content block and
 * automatically defines the options of the drop-down list and sets its values.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
 * ```
 */
let EditorFontSizeComponent = EditorFontSizeComponent_1 = class EditorFontSizeComponent extends ToolBarToolComponent {
    constructor(editor, dialogService, localization, toolsService) {
        super();
        this.editor = editor;
        this.dialogService = dialogService;
        this.localization = localization;
        this.toolsService = toolsService;
        this.disabled = false;
        this.tabindex = -1;
        /**
         * Fires when the user updates the value of the drop-down list.
         */
        this.valueChange = new EventEmitter();
        this._data = [
            { text: '8px', size: 8 },
            { text: '10px', size: 10 },
            { text: '12px', size: 12 },
            { text: '14px', size: 14 },
            { text: '18px', size: 18 },
            { text: '24px', size: 24 },
            { text: '36px', size: 36 }
        ];
    }
    /**
     * Overrides the default font size list.
     */
    set data(sizes) {
        this._data = sizes || this._data;
    }
    get data() {
        return this._data;
    }
    ngOnInit() {
        this.itemDisabled = itemArgs => {
            if (!this.overflows && this.fontSizeDropDownList && !this.fontSizeDropDownList.dropDownList.isOpen) {
                return true; //disable all items in order to prevent navigation when DDL is closed
            }
            else {
                return itemArgs.dataItem.size === null;
            }
        };
        setTimeout(() => {
            this.defaultItem = { text: this.title, size: null };
            this.toolsService.needsCheck.next();
        });
        this.subs = this.editor.stateChange.subscribe(({ style }) => {
            // remove units(px, em, rem...)
            // string#match returns array
            this.value = (getUniqueStyleValues(style.selected, 'font-size').match(/\d+/g) || [null])[0];
            this.disabled = style.disabled;
        });
    }
    /**
     * @hidden
     */
    onValueChange(ev) {
        if (isPresent(ev)) {
            this.editor.exec('fontSize', ev);
            this.editor.view.focus();
            this.valueChange.emit(this.data.find(d => d.size === parseInt(ev, 10)));
        }
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    get outerWidth() {
        if (this.element) {
            return outerWidth(this.element.nativeElement);
        }
    }
    get title() {
        return this.localization.get('fontSize');
    }
    /**
     * @hidden
     */
    openDialog() {
        const dialogSettings = {
            appendTo: this.editor.dialogContainer,
            content: FontSizeDialogComponent
        };
        this.editor.toolbar.toggle(false);
        const dialogContent = this.dialogService.open(dialogSettings).content.instance;
        dialogContent.setData({
            editor: this.editor,
            data: this.data,
            defaultItem: this.defaultItem,
            value: this.value,
            itemDisabled: this.itemDisabled
        });
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus() {
        this.tabindex = 0;
        if (this.overflows) {
            this.fontSizeButton.nativeElement.focus();
        }
        else {
            this.fontSizeDropDownList.focus();
        }
    }
    /**
     * @hidden
     */
    handleKey() {
        this.tabindex = -1;
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], EditorFontSizeComponent.prototype, "data", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], EditorFontSizeComponent.prototype, "valueChange", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorFontSizeComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorFontSizeComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('element', { static: false }),
    __metadata("design:type", ElementRef)
], EditorFontSizeComponent.prototype, "element", void 0);
__decorate([
    ViewChild('element', { read: FontSizeDropDownListComponent, static: false }),
    __metadata("design:type", FontSizeDropDownListComponent)
], EditorFontSizeComponent.prototype, "fontSizeDropDownList", void 0);
__decorate([
    ViewChild('fontSizeButton', { static: false }),
    __metadata("design:type", ElementRef)
], EditorFontSizeComponent.prototype, "fontSizeButton", void 0);
EditorFontSizeComponent = EditorFontSizeComponent_1 = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => EditorFontSizeComponent_1) }],
        selector: 'kendo-toolbar-dropdownlist[kendoEditorFontSize]',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-editor-fontsize-dropdownlist
                #element
                [defaultItem]="defaultItem"
                [data]="data"
                [(value)]="value"
                [itemDisabled]="itemDisabled"
                [title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (valueChange)="onValueChange($event)"
            >
            </kendo-editor-fontsize-dropdownlist>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                tabindex="-1"
                type="button"
                kendoButton
                #fontSizeButton
                class="k-overflow-button"
                [icon]="'font-size'"
                [attr.title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (click)="openDialog()"
            >
                {{ title }}
            </button>
        </ng-template>
    `
    }),
    __param(0, Host()),
    __metadata("design:paramtypes", [EditorComponent,
        DialogService,
        EditorLocalizationService,
        EditorToolsService])
], EditorFontSizeComponent);

var EditorFontFamilyComponent_1;
/**
 * A component which configures an existing `DropDownListComponent` as an Editor tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The component associates a `kendo-toolbar-dropdownlist` with an Editor command that changes the font family of a content block and
 * automatically defines the options of the drop-down list and sets its values.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
 * ```
 */
let EditorFontFamilyComponent = EditorFontFamilyComponent_1 = class EditorFontFamilyComponent extends ToolBarToolComponent {
    constructor(editor, dialogService, localization, toolsService) {
        super();
        this.editor = editor;
        this.dialogService = dialogService;
        this.localization = localization;
        this.toolsService = toolsService;
        this.disabled = false;
        this.tabindex = -1;
        /**
         * Fires when the user updates the value of the drop-down list.
         */
        this.valueChange = new EventEmitter();
        this._data = [
            { text: 'Arial', fontName: 'Arial,"Helvetica Neue",Helvetica,sans-serif' },
            { text: 'Courier New', fontName: '"Courier New",Courier,"Lucida Sans Typewriter","Lucida Typewriter",monospace' },
            { text: 'Georgia', fontName: 'Georgia,Times,"Times New Roman",serif' },
            {
                fontName: 
                // tslint:disable-next-line:max-line-length
                'Impact,Haettenschweiler,"Franklin Gothic Bold",Charcoal,"Helvetica Inserat","Bitstream Vera Sans Bold","Arial Black","sans serif"',
                text: 'Impact'
            },
            { text: 'Lucida Console', fontName: '"Lucida Console","Lucida Sans Typewriter",monaco,"Bitstream Vera Sans Mono",monospace' },
            { text: 'Tahoma', fontName: 'Tahoma,Verdana,Segoe,sans-serif' },
            { text: 'Times New Roman', fontName: 'TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif' },
            { text: 'Trebuchet MS', fontName: '"Trebuchet MS","Lucida Grande","Lucida Sans Unicode","Lucida Sans",Tahoma,sans-serif' },
            { text: 'Verdana', fontName: 'Verdana,Geneva,sans-serif' }
        ];
    }
    /**
     * Overrides the default font list.
     */
    set data(fonts) {
        this._data = fonts || this._data;
    }
    get data() {
        return this._data;
    }
    ngOnInit() {
        this.itemDisabled = (itemArgs) => {
            if (!this.overflows && this.fontFamilyDropDownList && !this.fontFamilyDropDownList.dropDownList.isOpen) {
                return true; //disable all items in order to prevent navigation when DDL is closed
            }
            else {
                return itemArgs.dataItem.fontName === null;
            }
        };
        setTimeout(() => {
            this.defaultItem = { text: this.title, fontName: null };
            this.toolsService.needsCheck.next();
        });
        this.subs = this.editor.stateChange.subscribe(({ style }) => {
            this.value = getUniqueStyleValues(style.selected, 'font-family') || null;
            this.disabled = style.disabled;
        });
    }
    /**
     * @hidden
     */
    onValueChange(ev) {
        if (isPresent(ev)) {
            this.editor.exec('fontFamily', ev);
            this.editor.view.focus();
            this.valueChange.emit(this.data.find(f => f.fontName === ev));
        }
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    get outerWidth() {
        if (this.element) {
            return outerWidth(this.element.nativeElement);
        }
    }
    get title() {
        return this.localization.get('fontFamily');
    }
    /**
     * @hidden
     */
    openDialog() {
        const dialogSettings = {
            appendTo: this.editor.dialogContainer,
            content: FontFamilyDialogComponent
        };
        this.editor.toolbar.toggle(false);
        const dialogContent = this.dialogService.open(dialogSettings).content.instance;
        dialogContent.setData({
            editor: this.editor,
            data: this.data,
            defaultItem: this.defaultItem,
            value: this.value,
            itemDisabled: this.itemDisabled
        });
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus() {
        this.tabindex = 0;
        if (this.overflows) {
            this.fontFamilyButton.nativeElement.focus();
        }
        else {
            this.fontFamilyDropDownList.focus();
        }
    }
    /**
     * @hidden
     */
    handleKey() {
        this.tabindex = -1;
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], EditorFontFamilyComponent.prototype, "data", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], EditorFontFamilyComponent.prototype, "valueChange", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorFontFamilyComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorFontFamilyComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('element', { static: false }),
    __metadata("design:type", ElementRef)
], EditorFontFamilyComponent.prototype, "element", void 0);
__decorate([
    ViewChild('element', { read: FontFamilyDropDownListComponent, static: false }),
    __metadata("design:type", FontFamilyDropDownListComponent)
], EditorFontFamilyComponent.prototype, "fontFamilyDropDownList", void 0);
__decorate([
    ViewChild('fontFamilyButton', { static: false }),
    __metadata("design:type", ElementRef)
], EditorFontFamilyComponent.prototype, "fontFamilyButton", void 0);
EditorFontFamilyComponent = EditorFontFamilyComponent_1 = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => EditorFontFamilyComponent_1) }],
        selector: 'kendo-toolbar-dropdownlist[kendoEditorFontFamily]',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-editor-fontfamily-dropdownlist
                #element
                [defaultItem]="defaultItem"
                [data]="data"
                [(value)]="value"
                [itemDisabled]="itemDisabled"
                [title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (valueChange)="onValueChange($event)"
            >
            </kendo-editor-fontfamily-dropdownlist>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                tabindex="-1"
                type="button"
                kendoButton
                #fontFamilyButton
                class="k-overflow-button"
                [icon]="'font-family'"
                [attr.title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (click)="openDialog()"
            >
                {{ title }}
            </button>
        </ng-template>
    `
    }),
    __param(0, Host()),
    __metadata("design:paramtypes", [EditorComponent,
        DialogService,
        EditorLocalizationService,
        EditorToolsService])
], EditorFontFamilyComponent);

// tslint:disable:no-forward-ref
var EditorColorPickerComponent_1;
/**
 * A component which configures an existing ColorPickerComponent as a ToolBar tool.
 * To associate a `kendo-toolbar-colorpicker` with an Editor command that changes the
 * foreground or the background color of the text, use the `kendoEditorForeColor` or `kendoEditorBackColor` directive.
 */
let EditorColorPickerComponent = EditorColorPickerComponent_1 = class EditorColorPickerComponent extends ToolBarToolComponent {
    constructor(editor, localization, dialogService, ngZone) {
        super();
        this.editor = editor;
        this.localization = localization;
        this.dialogService = dialogService;
        this.ngZone = ngZone;
        this.tabindex = -1;
        /**
         * Specifies if the component should be disabled.
         */
        this.disabled = false;
        /**
         * Specifies whether the ColorPicker will render a gradient, palette or combo view.
         *
         * @default 'palette'
         */
        this.view = 'palette';
    }
    /**
     * @hidden
     */
    get title() {
        return this.localization.get(this.editorCommand);
    }
    ngOnInit() {
        this.subs = this.editor.stateChange.subscribe(({ style }) => {
            this.disabled = style.disabled;
        });
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    handleValueChange(color) {
        this.editor.exec(this.editorCommand, color);
    }
    /**
     * @hidden
     */
    handleActiveColorClick(event) {
        event.preventOpen();
        this.handleValueChange(event.color);
    }
    /**
     * @hidden
     */
    onOpen(picker) {
        this.valueCache = picker.value;
        picker.reset();
    }
    /**
     * @hidden
     */
    onClose(picker) {
        if (!picker.value) {
            picker.value = this.valueCache;
        }
        this.ngZone.onStable.pipe(take(1)).subscribe(() => this.editor.view.focus());
    }
    /**
     * @hidden
     */
    get outerWidth() {
        if (this.element) {
            return outerWidth(this.element.nativeElement);
        }
    }
    /**
     * @hidden
     */
    openDialog() {
        const dialogSettings = {
            appendTo: this.editor.dialogContainer,
            content: ColorPickerDialogComponent
        };
        this.editor.toolbar.toggle(false);
        const dialogContent = this.dialogService.open(dialogSettings).content.instance;
        dialogContent.setData({
            editor: this.editor,
            value: this.value,
            title: this.title,
            editorCommand: this.editorCommand,
            paletteSettings: this.paletteSettings,
            icon: this.icon,
            view: this.view
        });
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus() {
        this.tabindex = 0;
        if (this.overflows) {
            this.colorPickerButton.nativeElement.focus();
        }
        else {
            this.colorPicker.focus();
        }
    }
    /**
     * @hidden
     */
    handleKey() {
        this.tabindex = -1;
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], EditorColorPickerComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], EditorColorPickerComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], EditorColorPickerComponent.prototype, "paletteSettings", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], EditorColorPickerComponent.prototype, "editorCommand", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], EditorColorPickerComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], EditorColorPickerComponent.prototype, "view", void 0);
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorColorPickerComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorColorPickerComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('colorpicker', { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], EditorColorPickerComponent.prototype, "element", void 0);
__decorate([
    ViewChild('colorpicker', { read: ColorPickerComponent, static: false }),
    __metadata("design:type", ColorPickerComponent)
], EditorColorPickerComponent.prototype, "colorPicker", void 0);
__decorate([
    ViewChild('colorPickerButton', { read: ElementRef, static: false }),
    __metadata("design:type", ElementRef)
], EditorColorPickerComponent.prototype, "colorPickerButton", void 0);
EditorColorPickerComponent = EditorColorPickerComponent_1 = __decorate([
    Component({
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => EditorColorPickerComponent_1) }],
        selector: 'kendo-toolbar-colorpicker',
        template: `
        <ng-template #toolbarTemplate>
            <kendo-colorpicker
                #colorpicker
                [view]="view"
                [format]="'hex'"
                [attr.title]="title"
                [icon]="icon"
                [value]="value"
                [paletteSettings]="paletteSettings"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (valueChange)="handleValueChange($event)"
                (activeColorClick)="handleActiveColorClick($event)"
                (open)="onOpen(colorpicker)"
                (close)="onClose(colorpicker)"
            >
            </kendo-colorpicker>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                tabindex="-1"
                type="button"
                kendoButton
                #colorPickerButton
                class="k-overflow-button"
                [icon]="icon"
                [attr.title]="title"
                [disabled]="disabled"
                [tabindex]="tabindex"
                (click)="openDialog()"
            >
                {{ title }}
            </button>
        </ng-template>
    `
    }),
    __param(0, Host()),
    __metadata("design:paramtypes", [EditorComponent,
        EditorLocalizationService,
        DialogService,
        NgZone])
], EditorColorPickerComponent);

var EditorInsertTableButtonComponent_1;
const popupWrapperWidth = '190px';
const popupWrapperHeight = '164px'; // Set to '192px' when TableWizard button is added;
/**
 * A toolbar component which allows the user to create and insert a table in the Editor's content.
 *
 * @example
 * ```ts-no-run
 * <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
 * ```
 */
let EditorInsertTableButtonComponent = EditorInsertTableButtonComponent_1 = class EditorInsertTableButtonComponent extends ToolBarToolComponent {
    constructor(editor, localization, popupService, dialogService) {
        super();
        this.editor = editor;
        this.localization = localization;
        this.popupService = popupService;
        this.dialogService = dialogService;
        this.open = false;
        this.buttonBlurred = new EventEmitter();
        this.cellClicked = new EventEmitter();
        this.subs = this.editor.stateChange.subscribe(({ insertTable }) => {
            this.disabled = insertTable.disabled;
        });
        this.subs.add(this.buttonBlurred.pipe(concatMap(() => interval(200).pipe(take(1), takeUntil(this.cellClicked)))).subscribe(() => {
            this.toggle(false);
        }));
    }
    ngOnDestroy() {
        this.destroyPopup();
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    get outerWidth() {
        if (this.element) {
            return outerWidth(this.element.nativeElement);
        }
    }
    get title() {
        return this.localization.get('insertTable');
    }
    /**
     * @hidden
     */
    toggle(open) {
        this.open = open === undefined ? !this.open : open;
        this.destroyPopup();
        if (this.open) {
            this.createPopup();
        }
    }
    /**
     * @hidden
     */
    openDialog() {
        const dialogSettings = {
            appendTo: this.editor.dialogContainer,
            content: InsertTableDialogComponent
        };
        this.editor.toolbar.toggle(false);
        const dialogContent = this.dialogService.open(dialogSettings).content.instance;
        dialogContent.setData({
            editor: this.editor
        });
    }
    /**
     * @hidden
     */
    onBlur() {
        this.tabIndex = -1;
        this.buttonBlurred.emit();
    }
    /**
     * @hidden
     */
    onCellClick(args) {
        this.cellClicked.emit();
        this.toggle(false);
        this.editor.exec('insertTable', args);
    }
    /**
     * @hidden
     */
    canFocus() {
        return !this.disabled;
    }
    /**
     * @hidden
     */
    focus() {
        this.tabIndex = 0;
        this.getButton().focus();
    }
    /**
     * @hidden
     */
    handleKey(ev) {
        if (ev.keyCode === Keys.Space || ev.keyCode === Keys.Enter) {
            return true;
        }
        this.tabIndex = -1;
        return false;
    }
    /**
     * @hidden
     */
    onTableWizardClick() {
        // this.toggle(false);
        // this.editor.openDialog("tableWizard");
    }
    createPopup() {
        const horizontalAlign = this.editor.direction === 'rtl' ? 'right' : 'left';
        const anchorPosition = { horizontal: horizontalAlign, vertical: 'bottom' };
        const popupPosition = { horizontal: horizontalAlign, vertical: 'top' };
        this.popupRef = this.popupService.open({
            anchor: this.element,
            anchorAlign: anchorPosition,
            animate: true,
            content: this.popupGridTemplate,
            popupAlign: popupPosition,
            popupClass: 'k-ct-popup k-group k-reset k-state-border-up',
            positionMode: 'absolute'
        });
        const popupWrapper = this.popupRef.popupElement;
        popupWrapper.style.width = popupWrapperWidth;
        popupWrapper.style.height = popupWrapperHeight;
        popupWrapper.setAttribute('dir', this.editor.direction);
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }
    }
    getButton() {
        return (this.overflows ? this.overflowElement : this.element).nativeElement;
    }
};
__decorate([
    ViewChild('toolbarTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorInsertTableButtonComponent.prototype, "toolbarTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: false }),
    __metadata("design:type", TemplateRef)
], EditorInsertTableButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('element', { static: false }),
    __metadata("design:type", ElementRef)
], EditorInsertTableButtonComponent.prototype, "element", void 0);
__decorate([
    ViewChild('overflowElement', { static: false }),
    __metadata("design:type", ElementRef)
], EditorInsertTableButtonComponent.prototype, "overflowElement", void 0);
__decorate([
    ViewChild('popupGridTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], EditorInsertTableButtonComponent.prototype, "popupGridTemplate", void 0);
EditorInsertTableButtonComponent = EditorInsertTableButtonComponent_1 = __decorate([
    Component({
        // tslint:disable-next-line:no-forward-ref
        providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => EditorInsertTableButtonComponent_1) }],
        selector: 'kendo-editor-insert-table-button',
        template: `
        <ng-template #toolbarTemplate>
            <button
                type="button"
                kendoButton
                #element
                [attr.title]="title"
                [attr.tabindex]="tabIndex"
                [icon]="'table-insert'"
                [disabled]="disabled"
                (click)="toggle()"
                (blur)="onBlur()"
            ></button>
        </ng-template>
        <ng-template #popupTemplate>
            <button
                kendoButton
                #overflowElement
                [attr.title]="title"
                [attr.tabindex]="tabIndex"
                [icon]="'table-insert'"
                [disabled]="disabled"
                (click)="openDialog()">
                {{ title }}
            </button>
        </ng-template>
        <ng-template #popupGridTemplate>
            <kendo-popup-table-grid (cellClick)="onCellClick($event)" (tableWizardClick)="onTableWizardClick()"></kendo-popup-table-grid>
        </ng-template>
    `
    }),
    __param(0, Host()),
    __metadata("design:paramtypes", [EditorComponent,
        EditorLocalizationService,
        PopupService,
        DialogService])
], EditorInsertTableButtonComponent);

/**
 * @hidden
 */
let PopupTableGridComponent = class PopupTableGridComponent {
    /**
     * @hidden
     */
    constructor() {
        this.cellClick = new EventEmitter();
        this.tableWizardClick = new EventEmitter();
        this.state = { rows: -1, cols: -1 };
        this.rows = 6;
        this.cols = 8;
    }
    get message() {
        const { rows, cols } = this.state;
        return `Create a ${rows > -1 ? rows + 1 : ''} ${cols > -1 ? 'x' : ''} ${cols > -1 ? cols + 1 : ''} table`;
    }
    get cells() {
        return Array.from(Array(this.rows * this.cols).keys());
    }
    selected(index) {
        const { rows, cols } = this.state;
        const cellRow = Math.floor(index / this.cols);
        const cellCol = index % this.cols;
        return cellRow <= rows && cellCol <= cols;
    }
    setState(index) {
        const rows = Math.floor(index / this.cols);
        const cols = index % this.cols;
        this.state = { rows: rows, cols: cols };
    }
    resetState() {
        this.state = { rows: -1, cols: -1 };
    }
    insertTable() {
        this.cellClick.emit(this.state);
    }
    openTableWizard() {
        this.tableWizardClick.emit();
    }
};
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PopupTableGridComponent.prototype, "cellClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PopupTableGridComponent.prototype, "tableWizardClick", void 0);
PopupTableGridComponent = __decorate([
    Component({
        selector: 'kendo-popup-table-grid',
        template: `
        <div style="border-color: inherit;" (mouseleave)="resetState()" (click)="insertTable()">
            <span *ngFor="let i of cells"
                class="k-ct-cell"
                [class.k-state-selected]="selected(i)"
                [class.k-state-disabled]="!selected(i)"
                (mouseenter)="setState(i)">
            </span>
        </div>
        <div class="k-status" unselectable="on">{{ message }}</div>
        <!-- uncomment when TableWizard is completed
        <div class="k-editor-toolbar" unselectable="on">
            <button type="button" kendoButton class="k-tool" [icon]="'table-wizard'" (click)="openTableWizard()" title="Table Wizard">Table Wizard</button>
        </div>
        -->
    `
    })
], PopupTableGridComponent);

/**
 * @hidden
 */
const commandIcons = {
    alignCenter: 'align-center',
    alignJustify: 'align-justify',
    alignLeft: 'align-left',
    alignRight: 'align-right',
    backColor: 'background',
    bold: 'bold',
    cleanFormatting: 'clear-css',
    createLink: 'link-horizontal',
    foreColor: 'foreground-color',
    indent: 'indent-increase',
    insertFile: 'file-add',
    insertImage: 'image',
    insertOrderedList: 'list-ordered',
    insertUnorderedList: 'list-unordered',
    italic: 'italic',
    outdent: 'indent-decrease',
    print: 'print',
    redo: 'redo',
    selectAll: 'select-all',
    strikethrough: 'strikethrough',
    subscript: 'sub-script',
    superscript: 'sup-script',
    underline: 'underline',
    undo: 'undo',
    unlink: 'unlink-horizontal',
    viewSource: 'html',
    //table
    insertTable: 'table-insert',
    addColumnBefore: 'table-column-insert-left',
    addColumnAfter: 'table-column-insert-right',
    addRowBefore: 'table-row-insert-above',
    addRowAfter: 'table-row-insert-below',
    deleteRow: 'table-row-delete',
    deleteColumn: 'table-column-delete',
    mergeCells: 'cells-merge',
    splitCell: 'cell-split-horizontally',
    deleteTable: 'table-delete'
    // tableWizard: 'table-wizard'
};

/**
 * @hidden
 */
class EditorCommandBase {
    constructor(command, button, editor, localization, toolsService) {
        this.command = command;
        this.button = button;
        this.editor = editor;
        this.localization = localization;
        this.toolsService = toolsService;
        setTimeout(() => {
            const text = this.localization.get(this.command);
            if (text) {
                this.button.showText = 'overflow';
                this.button.showIcon = 'both';
                this.button.text = text;
            }
            if (!this.button.toolbarOptions.icon) {
                this.button.icon = commandIcons[this.command];
            }
            this.button.title = text;
            this.toolsService.needsCheck.next();
        });
    }
    ngOnInit() {
        this.subs = this.editor.stateChange.subscribe(this.onStateChange.bind(this));
        this.subs.add(this.button.click.subscribe((this.clickHandler.bind(this))));
        this.subs.add(this.button.pointerdown.subscribe((this.pointerdownHandler.bind(this))));
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    // tslint:disable-next-line
    clickHandler() { }
    // tslint:disable-next-line
    pointerdownHandler(_event) { }
    // tslint:disable-next-line
    onStateChange(_toolBarState) { }
}

/**
 * @hidden
 */
class EditorCommandButton extends EditorCommandBase {
    constructor(command, button, editor, localization, toolsService) {
        super(command, button, editor, localization, toolsService);
        this.command = command;
        this.button = button;
        this.editor = editor;
        this.localization = localization;
        this.toolsService = toolsService;
    }
    clickHandler() {
        this.editor.exec(this.command, this.editor.applyToWord);
        this.editor.focus();
    }
    pointerdownHandler(e) {
        e.preventDefault();
    }
    onStateChange(toolBarState) {
        this.button.selected = toolBarState[this.command].selected;
        this.button.disabled = toolBarState[this.command].disabled;
    }
}

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AlignLeft tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignLeftButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorAlignLeftButtonDirective = class EditorAlignLeftButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('alignLeft', button, editor, localization, toolsService);
    }
};
EditorAlignLeftButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAlignLeftButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAlignLeftButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AlignCenter tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignCenterButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorAlignCenterButtonDirective = class EditorAlignCenterButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('alignCenter', button, editor, localization, toolsService);
    }
};
EditorAlignCenterButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAlignCenterButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAlignCenterButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AlignRight tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignRightButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorAlignRightButtonDirective = class EditorAlignRightButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('alignRight', button, editor, localization, toolsService);
    }
};
EditorAlignRightButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAlignRightButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAlignRightButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AlignJustify tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAlignJustifyButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorAlignJustifyButtonDirective = class EditorAlignJustifyButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('alignJustify', button, editor, localization, toolsService);
    }
};
EditorAlignJustifyButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAlignJustifyButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAlignJustifyButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Redo tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorRedoButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorRedoButtonDirective = class EditorRedoButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('redo', button, editor, localization, toolsService);
    }
};
EditorRedoButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorRedoButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorRedoButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Undo tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorUndoButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorUndoButtonDirective = class EditorUndoButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('undo', button, editor, localization, toolsService);
    }
};
EditorUndoButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorUndoButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorUndoButtonDirective);

/**
 * @hidden
 */
class EditorCommandDialog extends EditorCommandBase {
    constructor(dialog, button, editor, localization, toolsService) {
        super(dialog, button, editor, localization, toolsService);
        this.dialog = dialog;
        this.button = button;
        this.editor = editor;
        this.localization = localization;
        this.toolsService = toolsService;
    }
    clickHandler() {
        this.editor.openDialog(this.dialog);
    }
    onStateChange(toolBarState) {
        this.button.selected = toolBarState[this.command].selected;
        this.button.disabled = toolBarState[this.command].disabled;
    }
}

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor InsertImage tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertImageButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorInsertImageButtonDirective = class EditorInsertImageButtonDirective extends EditorCommandDialog {
    constructor(button, editor, localization, toolsService) {
        super('insertImage', button, editor, localization, toolsService);
    }
};
EditorInsertImageButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorInsertImageButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorInsertImageButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Indent tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorIndentButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorIndentButtonDirective = class EditorIndentButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('indent', button, editor, localization, toolsService);
    }
};
EditorIndentButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorIndentButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorIndentButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Outdent tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorOutdentButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorOutdentButtonDirective = class EditorOutdentButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('outdent', button, editor, localization, toolsService);
    }
};
EditorOutdentButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorOutdentButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorOutdentButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor CreateLink tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorCreateLinkButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorCreateLinkButtonDirective = class EditorCreateLinkButtonDirective extends EditorCommandDialog {
    constructor(button, editor, localization, toolsService) {
        super('createLink', button, editor, localization, toolsService);
    }
};
EditorCreateLinkButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorCreateLinkButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorCreateLinkButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Unlink tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorUnlinkButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorUnlinkButtonDirective = class EditorUnlinkButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('unlink', button, editor, localization, toolsService);
    }
};
EditorUnlinkButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorUnlinkButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorUnlinkButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor InsertOrderedList tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertOrderedListButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorInsertOrderedListButtonDirective = class EditorInsertOrderedListButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('insertOrderedList', button, editor, localization, toolsService);
    }
};
EditorInsertOrderedListButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorInsertOrderedListButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorInsertOrderedListButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor InsertUnorderedList tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertUnorderedListButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorInsertUnorderedListButtonDirective = class EditorInsertUnorderedListButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('insertUnorderedList', button, editor, localization, toolsService);
    }
};
EditorInsertUnorderedListButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorInsertUnorderedListButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorInsertUnorderedListButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor ViewSource tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorViewSourceButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorViewSourceButtonDirective = class EditorViewSourceButtonDirective extends EditorCommandDialog {
    constructor(button, editor, localization, toolsService) {
        super('viewSource', button, editor, localization, toolsService);
    }
};
EditorViewSourceButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorViewSourceButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorViewSourceButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Bold tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorBoldButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorBoldButtonDirective = class EditorBoldButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('bold', button, editor, localization, toolsService);
    }
};
EditorBoldButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorBoldButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorBoldButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Italic tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorItalicButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorItalicButtonDirective = class EditorItalicButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('italic', button, editor, localization, toolsService);
    }
};
EditorItalicButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorItalicButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorItalicButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Underline tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorUnderlineButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorUnderlineButtonDirective = class EditorUnderlineButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('underline', button, editor, localization, toolsService);
    }
};
EditorUnderlineButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorUnderlineButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorUnderlineButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Strikethrough tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorStrikethroughButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorStrikethroughButtonDirective = class EditorStrikethroughButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('strikethrough', button, editor, localization, toolsService);
    }
};
EditorStrikethroughButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorStrikethroughButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorStrikethroughButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Subscript tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSubscriptButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorSubscriptButtonDirective = class EditorSubscriptButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('subscript', button, editor, localization, toolsService);
    }
};
EditorSubscriptButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorSubscriptButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorSubscriptButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Superscript tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `selected` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSuperscriptButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorSuperscriptButtonDirective = class EditorSuperscriptButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('superscript', button, editor, localization, toolsService);
    }
};
EditorSuperscriptButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorSuperscriptButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorSuperscriptButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor 'Insert File' tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
 * ```
 */
let EditorInsertFileButtonDirective = class EditorInsertFileButtonDirective extends EditorCommandDialog {
    constructor(button, editor, localization, toolsService) {
        super('insertFile', button, editor, localization, toolsService);
    }
};
EditorInsertFileButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorInsertFileButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorInsertFileButtonDirective);

/**
 * A directive which configures an `EditorColorPickerComponent`
 * for manipulating the foreground color of the text
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 */
let EditorForeColorDirective = class EditorForeColorDirective {
    constructor(colorPicker) {
        this.colorPicker = colorPicker;
        this.colorPicker.icon = commandIcons.foreColor;
        this.colorPicker.editorCommand = 'foreColor';
    }
};
EditorForeColorDirective = __decorate([
    Directive({
        selector: '[kendoEditorForeColor]'
    }),
    __metadata("design:paramtypes", [EditorColorPickerComponent])
], EditorForeColorDirective);

/**
 * A directive which configures an `EditorColorPickerComponent`
 * for manipulating the background color of the text
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 */
let EditorBackColorDirective = class EditorBackColorDirective {
    constructor(colorPicker) {
        this.colorPicker = colorPicker;
        this.colorPicker.icon = commandIcons.backColor;
        this.colorPicker.editorCommand = 'backColor';
    }
};
EditorBackColorDirective = __decorate([
    Directive({
        selector: '[kendoEditorBackColor]'
    }),
    __metadata("design:paramtypes", [EditorColorPickerComponent])
], EditorBackColorDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Clean Formatting tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorCleanFormattingButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorCleanFormattingButtonDirective = class EditorCleanFormattingButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('cleanFormatting', button, editor, localization, toolsService);
    }
};
EditorCleanFormattingButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorCleanFormattingButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorCleanFormattingButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AddColumnBefore tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
 * ```
 */
let EditorAddColumnBeforeButtonDirective = class EditorAddColumnBeforeButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('addColumnBefore', button, editor, localization, toolsService);
    }
};
EditorAddColumnBeforeButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAddColumnBeforeButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAddColumnBeforeButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AddColumnAfter tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
 * ```
 */
let EditorAddColumnAfterButtonDirective = class EditorAddColumnAfterButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('addColumnAfter', button, editor, localization, toolsService);
    }
};
EditorAddColumnAfterButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAddColumnAfterButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAddColumnAfterButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AddRowBefore tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
 * ```
 */
let EditorAddRowBeforeButtonDirective = class EditorAddRowBeforeButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('addRowBefore', button, editor, localization, toolsService);
    }
};
EditorAddRowBeforeButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAddRowBeforeButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAddRowBeforeButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor AddRowAfter tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
 * ```
 */
let EditorAddRowAfterButtonDirective = class EditorAddRowAfterButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('addRowAfter', button, editor, localization, toolsService);
    }
};
EditorAddRowAfterButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorAddRowAfterButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorAddRowAfterButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor DeleteColumn tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
 * ```
 */
let EditorDeleteColumnButtonDirective = class EditorDeleteColumnButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('deleteColumn', button, editor, localization, toolsService);
    }
};
EditorDeleteColumnButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorDeleteColumnButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorDeleteColumnButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor DeleteRow tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
 * ```
 */
let EditorDeleteRowButtonDirective = class EditorDeleteRowButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('deleteRow', button, editor, localization, toolsService);
    }
};
EditorDeleteRowButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorDeleteRowButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorDeleteRowButtonDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor DeleteTable tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
 * ```
 */
let EditorDeleteTableButtonDirective = class EditorDeleteTableButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('deleteTable', button, editor, localization, toolsService);
    }
};
EditorDeleteTableButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorDeleteTableButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorDeleteTableButtonDirective);

/**
 * @hidden
 *
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor MergeCells tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
 * ```
 */
let EditorMergeCellsButtonDirective = class EditorMergeCellsButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('mergeCells', button, editor, localization, toolsService);
    }
};
EditorMergeCellsButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorMergeCellsButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorMergeCellsButtonDirective);

/**
 * @hidden
 *
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor SplitCell tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 * In addition, the directive updates the `disabled` state of the button according to the cursor position in the editing area.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
 * ```
 */
let EditorSplitCellButtonDirective = class EditorSplitCellButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('splitCell', button, editor, localization, toolsService);
    }
};
EditorSplitCellButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorSplitCellButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorSplitCellButtonDirective);

/**
 * @hidden
 */
class Messages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "alignCenter", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "alignJustify", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "alignLeft", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "alignRight", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "backColor", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "bold", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "cleanFormatting", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "createLink", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "fontFamily", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "fontSize", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "foreColor", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "format", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "indent", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "insertFile", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "insertImage", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "insertOrderedList", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "insertUnorderedList", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "italic", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "outdent", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "print", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "redo", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "selectAll", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "strikethrough", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "subscript", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "superscript", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "underline", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "undo", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "unlink", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "viewSource", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "insertTable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "addColumnBefore", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "addColumnAfter", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "addRowBefore", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "addRowAfter", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "deleteColumn", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "deleteRow", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "deleteTable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "dialogApply", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "dialogCancel", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "dialogInsert", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "dialogUpdate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "fileText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "fileTitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "fileWebAddress", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "imageAltText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "imageHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "imageWebAddress", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "imageWidth", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "linkOpenInNewWindow", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "linkText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "linkTitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "linkWebAddress", void 0);

var LocalizedMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedMessagesDirective = LocalizedMessagesDirective_1 = class LocalizedMessagesDirective extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedMessagesDirective = LocalizedMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: Messages,
                // tslint:disable-next-line:no-forward-ref
                useExisting: forwardRef(() => LocalizedMessagesDirective_1)
            }
        ],
        selector: '[kendoEditorLocalizedMessages]'
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedMessagesDirective);

var CustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug globalization_editor %}#toc-localization)).
 */
let CustomMessagesComponent = CustomMessagesComponent_1 = class CustomMessagesComponent extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
CustomMessagesComponent = CustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: Messages,
                // tslint:disable-next-line:no-forward-ref
                useExisting: forwardRef(() => CustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-editor-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], CustomMessagesComponent);

/**
 * @hidden
 */
let DropDownToolDirective = class DropDownToolDirective {
    constructor(hostEl, zone) {
        this.hostEl = hostEl;
        this.zone = zone;
        this.preventDefault = (event) => {
            event.preventDefault();
        };
    }
    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.hostEl.nativeElement.addEventListener('pointerdown', this.preventDefault);
        });
    }
    ngOnDestroy() {
        this.hostEl.nativeElement.removeEventListener('pointerdown', this.preventDefault);
    }
};
DropDownToolDirective = __decorate([
    Directive({ selector: '[kendoEditorDropDownTool]' }),
    __metadata("design:paramtypes", [ElementRef,
        NgZone])
], DropDownToolDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor Print tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 *
 * > The Editor Print tool is supported in the default [`iframe`](https://www.telerik.com/kendo-angular-ui-develop/components/editor/api/EditorComponent/#toc-iframe) mode only.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorPrintButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorPrintDirective = class EditorPrintDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('print', button, editor, localization, toolsService);
        this.editor = editor;
    }
    ngAfterViewInit() {
        if (isDevMode) {
            if (!this.editor.iframe) {
                throw new Error(EditorErrorMessages.printTool);
            }
        }
    }
    clickHandler() {
        if (this.editor.iframe) {
            const view = this.editor.view;
            const dom = view && view.dom;
            const doc = dom && dom.ownerDocument;
            const editorWindow = doc && doc.defaultView;
            if (editorWindow) {
                editorWindow.print();
            }
        }
    }
};
EditorPrintDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorPrintButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorPrintDirective);

/**
 * A directive which configures an existing `ToolBarButtonComponent` as an Editor SelectAll tool
 * ([see example]({% slug toolbartools_editor %}#toc-built-in-tools)).
 * The directive will predefine the `icon` and `click` event handlers of the button.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
 * ```
 *
 * The following example demonstrates how to change the default icon that is applied by the directive.
 *
 * @example
 * ```ts-no-run
 * <kendo-toolbar-button kendoEditorSelectAllButton icon="blogger"></kendo-toolbar-button>
 * ```
 */
let EditorSelectAllButtonDirective = class EditorSelectAllButtonDirective extends EditorCommandButton {
    constructor(button, editor, localization, toolsService) {
        super('selectAll', button, editor, localization, toolsService);
    }
    clickHandler() {
        this.editor.shouldEmitFocus = true;
        super.clickHandler();
    }
};
EditorSelectAllButtonDirective = __decorate([
    Directive({
        selector: 'kendo-toolbar-button[kendoEditorSelectAllButton]'
    }),
    __param(1, Host()),
    __metadata("design:paramtypes", [ToolBarButtonComponent,
        EditorComponent,
        EditorLocalizationService,
        EditorToolsService])
], EditorSelectAllButtonDirective);

const COMPONENT_DIRECTIVES = [
    //alignment
    EditorAlignLeftButtonDirective,
    EditorAlignCenterButtonDirective,
    EditorAlignRightButtonDirective,
    EditorAlignJustifyButtonDirective,
    //file
    EditorInsertFileButtonDirective,
    //history
    EditorRedoButtonDirective,
    EditorUndoButtonDirective,
    //image
    EditorInsertImageButtonDirective,
    //indent
    EditorIndentButtonDirective,
    EditorOutdentButtonDirective,
    //link
    EditorCreateLinkButtonDirective,
    EditorUnlinkButtonDirective,
    //list
    EditorInsertOrderedListButtonDirective,
    EditorInsertUnorderedListButtonDirective,
    //source
    EditorViewSourceButtonDirective,
    //typographical emphasis
    EditorBoldButtonDirective,
    EditorItalicButtonDirective,
    EditorUnderlineButtonDirective,
    EditorStrikethroughButtonDirective,
    EditorSubscriptButtonDirective,
    EditorSuperscriptButtonDirective,
    //color
    EditorForeColorDirective,
    EditorBackColorDirective,
    //clear format
    EditorCleanFormattingButtonDirective,
    //table
    EditorAddColumnBeforeButtonDirective,
    EditorAddColumnAfterButtonDirective,
    EditorAddRowBeforeButtonDirective,
    EditorAddRowAfterButtonDirective,
    EditorDeleteColumnButtonDirective,
    EditorDeleteRowButtonDirective,
    EditorDeleteTableButtonDirective,
    EditorMergeCellsButtonDirective,
    EditorSplitCellButtonDirective,
    // EditorTableWizardButtonDirective,
    //localization
    CustomMessagesComponent,
    LocalizedMessagesDirective,
    // dropdown tools
    DropDownToolDirective,
    // print
    EditorPrintDirective,
    // select all
    EditorSelectAllButtonDirective
];
const TOOLBAR_TOOLS = [
    EditorFontSizeComponent,
    EditorFontFamilyComponent,
    EditorFormatComponent,
    EditorColorPickerComponent,
    EditorInsertTableButtonComponent
];
const TOOLBAR_DIALOGS = [
    FileLinkDialogComponent,
    ImageDialogComponent,
    SourceDialogComponent,
    FormatDialogComponent,
    ColorPickerDialogComponent,
    FontFamilyDialogComponent,
    FontSizeDialogComponent,
    InsertTableDialogComponent
    // TableWizardDialogComponent
    // Table Wizard Building Blocks
    // TableSettingsComponent,
    // CellSettingsComponent,
    // AccessibilitySettingsComponent
];
const INTERNAL_COMPONENTS = [
    PopupTableGridComponent,
    FormatDropDownListComponent,
    FontFamilyDropDownListComponent,
    FontSizeDropDownListComponent
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}) definition for the Editor component.
 *
 * The package exports:
 * - `EditorComponent`&mdash;The `EditorComponent` class.
 * - `EditorButtonDirective`&mdash;The `EditorButton` directive class.
 * - `EditorDropDownDirective`&mdash;The `EditorDropDown` directive class.
 * - `EditorDialogDirective`&mdash;The `EditorDialog` directive class.
 * - `ToolBarDropDownListComponent`&mdash;The `ToolBarDropDownListComponent` directive class.
 * - `ButtonModule`&mdash;The `KendoButton` module.
 * - `ToolBarModule`&mdash;The `KendoToolBar` module.
 *
 *  * @example
 *
 * ```ts-no-run
 * // Import the Editor module
 * import { EditorModule } from '@progress/kendo-angular-editor';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare the app component
 *     imports:      [BrowserModule, EditorModule], // import the Editor module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let EditorModule = class EditorModule {
};
EditorModule = __decorate([
    NgModule({
        declarations: [
            EditorComponent,
            COMPONENT_DIRECTIVES,
            TOOLBAR_TOOLS,
            TOOLBAR_DIALOGS,
            INTERNAL_COMPONENTS
        ],
        entryComponents: [
            TOOLBAR_DIALOGS
        ],
        exports: [
            EditorComponent,
            COMPONENT_DIRECTIVES,
            TOOLBAR_TOOLS,
            ToolBarModule,
            ButtonModule,
            FormsModule,
            ReactiveFormsModule,
            //needed for unit tests
            INTERNAL_COMPONENTS
        ],
        imports: [
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            //Kendo UI Angular Modules
            ButtonModule,
            ColorPickerModule,
            DialogModule,
            DropDownsModule,
            NumericTextBoxModule,
            ToolBarModule,
            TextBoxModule
        ]
    })
], EditorModule);

/**
 * Generated bundle index. Do not edit.
 */

export { ColorPickerDialogComponent, FileLinkDialogComponent, FontFamilyDialogComponent, FontSizeDialogComponent, FormatDialogComponent, ImageDialogComponent, InsertTableDialogComponent, SourceDialogComponent, CustomMessagesComponent, EditorLocalizationService, LocalizedMessagesDirective, Messages, EditorAlignCenterButtonDirective, EditorAlignJustifyButtonDirective, EditorAlignLeftButtonDirective, EditorAlignRightButtonDirective, EditorBackColorDirective, EditorColorPickerComponent, EditorForeColorDirective, EditorCleanFormattingButtonDirective, FontFamilyDropDownListComponent, EditorFontFamilyComponent, FontSizeDropDownListComponent, EditorFontSizeComponent, FormatDropDownListComponent, EditorFormatComponent, EditorRedoButtonDirective, EditorUndoButtonDirective, EditorInsertImageButtonDirective, EditorIndentButtonDirective, EditorOutdentButtonDirective, EditorCreateLinkButtonDirective, EditorInsertFileButtonDirective, EditorUnlinkButtonDirective, EditorInsertOrderedListButtonDirective, EditorInsertUnorderedListButtonDirective, EditorPrintDirective, EditorSelectAllButtonDirective, DropDownToolDirective, EditorCommandBase, EditorCommandButton, EditorCommandDialog, EditorViewSourceButtonDirective, EditorAddColumnAfterButtonDirective, EditorAddColumnBeforeButtonDirective, EditorAddRowAfterButtonDirective, EditorAddRowBeforeButtonDirective, EditorDeleteColumnButtonDirective, EditorDeleteRowButtonDirective, EditorDeleteTableButtonDirective, EditorInsertTableButtonComponent, EditorMergeCellsButtonDirective, EditorSplitCellButtonDirective, PopupTableGridComponent, EditorToolsService, EditorBoldButtonDirective, EditorItalicButtonDirective, EditorStrikethroughButtonDirective, EditorSubscriptButtonDirective, EditorSuperscriptButtonDirective, EditorUnderlineButtonDirective, EditorComponent, EditorModule, schema };
