/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { isDevMode, Input, ViewChild, ElementRef, Component, ChangeDetectionStrategy, Renderer2, NgZone, NgModule } from '@angular/core';
import { Barcode, QRCode, barcodeValidator, qrcodeValidator } from '@progress/kendo-charts';
import { isDocumentAvailable, ResizeSensorModule } from '@progress/kendo-angular-common';
import { exportImage, exportSVG } from '@progress/kendo-drawing';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-barcodes',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1646216855,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * @hidden
 */
class BaseComponent {
    constructor(element, renderer, ngZone) {
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.resizeRateLimit = 10;
        validatePackage(packageMetadata);
    }
    get autoResize() {
        return this.resizeRateLimit > 0;
    }
    get canRender() {
        return isDocumentAvailable() && Boolean(this.element);
    }
    ngAfterViewInit() {
        this.refresh();
    }
    ngOnChanges(changes) {
        // Need to create a new instance if the rendering mode changes.
        if (changes['renderAs'] && this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
        this.refresh();
    }
    /**
     * Detects the size of the container and redraws the component.
     * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
     */
    resize() {
        if (this.instance) {
            this.instance.redraw();
        }
    }
    /**
     * @hidden
     */
    onResize() {
        if (this.autoResize) {
            this.resize();
        }
    }
    /**
     * Exports the component as an image. The export operation is asynchronous and returns a promise.
     *
     * @param {ImageExportOptions} options - The parameters for the exported image.
     * @returns {Promise<string>} - A promise that will be resolved with a PNG image encoded as a Data URI.
     */
    exportImage(options = {}) {
        return exportImage(this.exportVisual(), options);
    }
    /**
     * Exports the component as an SVG document. The export operation is asynchronous and returns a promise.
     *
     * @param options - The parameters for the exported file.
     * @returns - A promise that will be resolved with an SVG document that is encoded as a Data URI.
     */
    exportSVG(options = {}) {
        return exportSVG(this.exportVisual(), options);
    }
    /**
     * Exports the component as a Drawing Group.
     *
     * @returns - The exported Group.
     */
    exportVisual() {
        return this.instance.exportVisual();
    }
    refresh() {
        if (!this.canRender) {
            return;
        }
        if (!this.instance) {
            const element = this.element.nativeElement;
            this.instance = this.createInstance(element, this.options);
        }
        else {
            this.instance.setOptions(this.options);
        }
    }
    isDevMode() {
        return isDevMode();
    }
}
__decorate([
    Input(),
    __metadata("design:type", Number)
], BaseComponent.prototype, "resizeRateLimit", void 0);
__decorate([
    ViewChild('surface', { static: true }),
    __metadata("design:type", ElementRef)
], BaseComponent.prototype, "surfaceElement", void 0);

/**
 * Represents the Kendo UI Barcode component for Angular.
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-barcode type="EAN8" value="1234567">
 *        </kendo-barcode>
 *    `
 * })
 * export class AppComponent {
 * }
 * ```
 */
let BarcodeComponent = class BarcodeComponent extends BaseComponent {
    constructor(element, renderer, ngZone) {
        super(element, renderer, ngZone);
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        /**
         * Limits the automatic resizing of the Barcode. Sets the maximum number of times per second
         * that the component redraws its content when the size of its container changes.
         * Defaults to `10`. To disable the automatic resizing, set it to `0`.
         *
         * @example
         * ```ts
         * _@Component({
         *     selector: 'my-app',
         *     template: `
         *         <kendo-barcode type="EAN8" [value]="1234567"
         *                        [resizeRateLimit]="2">
         *         </kendo-barcode>
         *   `
         * })
         * export class AppComponent {
         * }
         * ```
         */
        this.resizeRateLimit = 10;
    }
    get options() {
        return {
            renderAs: this.renderAs,
            background: this.background,
            border: this.border,
            checksum: this.checksum,
            color: this.color,
            height: this.height,
            padding: this.padding,
            text: this.text,
            type: this.type,
            value: this.value,
            width: this.width
        };
    }
    createInstance(element, options) {
        return new Barcode(element, options, this.onError.bind(this));
    }
    onError(error) {
        error.name = packageMetadata.productName + ' Barcode';
        if (this.isDevMode()) {
            throw error;
        }
        else {
            console.warn(error);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], BarcodeComponent.prototype, "background", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], BarcodeComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], BarcodeComponent.prototype, "checksum", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], BarcodeComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], BarcodeComponent.prototype, "height", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], BarcodeComponent.prototype, "padding", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], BarcodeComponent.prototype, "renderAs", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], BarcodeComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], BarcodeComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], BarcodeComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], BarcodeComponent.prototype, "width", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], BarcodeComponent.prototype, "resizeRateLimit", void 0);
BarcodeComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        exportAs: 'kendoBarcode',
        selector: 'kendo-barcode',
        template: `
        <kendo-resize-sensor (resize)="onResize()" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        NgZone])
], BarcodeComponent);

const DEFAULT_COLOR = '#000';
const DEFAULT_BACKGROUND = '#fff';
const DEFAULT_ERROR_CORRECTION = 'L';
/**
 * Represents the Kendo UI QR Code component for Angular.
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-qrcode value="https://www.telerik.com/kendo-angular-ui">
 *        </kendo-qrcode>
 *    `
 * })
 * export class AppComponent {
 * }
 * ```
 */
let QRCodeComponent = class QRCodeComponent extends BaseComponent {
    constructor(element, renderer, ngZone) {
        super(element, renderer, ngZone);
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        /**
         * Limits the automatic resizing of the QR Code. Sets the maximum number of times per second
         * that the component redraws its content when the size of its container changes.
         * Defaults to `10`. To disable the automatic resizing, set it to `0`.
         *
         * @example
         * ```ts
         * _@Component({
         *     selector: 'my-app',
         *     template: `
         *         <kendo-qrcode value="https://www.telerik.com/kendo-angular-ui"
         *                       [resizeRateLimit]="2">
         *         </kendo-qrcode>
         *   `
         * })
         * export class AppComponent {
         * }
         * ```
         */
        this.resizeRateLimit = 10;
    }
    get options() {
        return {
            background: this.background || DEFAULT_BACKGROUND,
            border: this.border,
            color: this.color || DEFAULT_COLOR,
            encoding: this.encoding,
            errorCorrection: this.errorCorrection || DEFAULT_ERROR_CORRECTION,
            overlay: this.overlay || {},
            padding: this.padding,
            renderAs: this.renderAs,
            size: this.size,
            value: this.value
        };
    }
    createInstance(element, options) {
        return new QRCode(element, options, this.onError.bind(this));
    }
    onError(error) {
        error.name = packageMetadata.productName + ' QRCode';
        if (this.isDevMode()) {
            throw error;
        }
        else {
            console.warn(error);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], QRCodeComponent.prototype, "background", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], QRCodeComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], QRCodeComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], QRCodeComponent.prototype, "encoding", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], QRCodeComponent.prototype, "errorCorrection", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], QRCodeComponent.prototype, "overlay", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], QRCodeComponent.prototype, "padding", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], QRCodeComponent.prototype, "renderAs", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], QRCodeComponent.prototype, "size", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], QRCodeComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], QRCodeComponent.prototype, "resizeRateLimit", void 0);
QRCodeComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'kendo-qrcode',
        exportAs: 'kendoQRCode',
        template: `
        <kendo-resize-sensor (resize)="onResize()" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        NgZone])
], QRCodeComponent);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Barcode component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Barcode module
 * import { BarcodeModule } from '@progress/kendo-angular-barcodes';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BarcodeModule], // import Barcode module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let BarcodeModule = class BarcodeModule {
};
BarcodeModule = __decorate([
    NgModule({
        declarations: [BarcodeComponent],
        imports: [ResizeSensorModule],
        exports: [BarcodeComponent]
    })
], BarcodeModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the QR Code component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the QR Code module
 * import { QRCodeModule } from '@progress/kendo-angular-barcodes';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, QRCodeModule], // import QRCodeModule module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let QRCodeModule = class QRCodeModule {
};
QRCodeModule = __decorate([
    NgModule({
        declarations: [QRCodeComponent],
        imports: [ResizeSensorModule],
        exports: [QRCodeComponent]
    })
], QRCodeModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Barcode and QR Code components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Barcodes module
 * import { BarcodesModule } from '@progress/kendo-angular-barcodes';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BarcodesModule], // import Barcodes module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let BarcodesModule = class BarcodesModule {
};
BarcodesModule = __decorate([
    NgModule({
        exports: [BarcodeModule, QRCodeModule]
    })
], BarcodesModule);

/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * Creates a value validator for a particular Barcode type.
 *
 * @param {BarcodeType} type The type of the Barcode.
 * @param {Size} size The size of the barcode, excluding the text label, padding and border. Optional.
 * @returns {ValidatorFn}  A validator function that returns an error map with the `barcode` property if the validation check fails, otherwise `null`.
 *
 * @example
 * ```ts-no-run
 * const control = new FormControl('1234', createBarcodeValidator('EAN8'));
 * console.log(control.errors);
 *
 * // {
 * //   barcode: {
 * //     message: 'The value of the "EAN13" encoding should be 12 symbols',
 * //     value: '1234',
 * //     type: 'EAN13'
 * //   }
 * // }
 * ```
 */
const createBarcodeValidator = (type, size) => {
    const validator = barcodeValidator(type, size);
    return (control) => {
        if (!isPresent(control.value) || control.value === '') {
            return null;
        }
        const result = validator(control.value);
        if (result.valid === true) {
            return null;
        }
        return {
            barcode: {
                message: result.error.message,
                value: control.value,
                type: type
            }
        };
    };
};

/**
 * Creates a value validator for a particular Barcode type.
 *
 * @param {QRCodeEncoding} encoding The QR Code encoding. Defaults to 'ISO_8859_1'.
 * @returns {ValidatorFn}  A validator function that returns an error map with the `qrcode` property if the validation check fails, otherwise `null`.
 *
 * @example
 * ```ts-no-run
 * const control = new FormControl('Фоо', createQRCodeValidator());
 * console.log(control.errors);
 *
 * // {
 * //   qrcode: {
 * //     message: 'Unsupported character in QR Code: "Ф".',
 * //     value: '1234',
 * //     type: 'EAN13'
 * //   }
 * // }
 * ```
 */
const createQRCodeValidator = (encoding = 'ISO_8859_1') => {
    const validator = qrcodeValidator(encoding);
    return (control) => {
        if (!control.value) {
            return null;
        }
        const result = validator(control.value);
        if (result.valid === true) {
            return null;
        }
        return {
            qrcode: {
                message: result.error.message,
                value: control.value,
                encoding: encoding
            }
        };
    };
};

/**
 * Generated bundle index. Do not edit.
 */

export { BaseComponent, BarcodeComponent, QRCodeComponent, BarcodeModule, QRCodeModule, BarcodesModule, createBarcodeValidator, createQRCodeValidator };
